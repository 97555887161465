import React, { Component } from "react";

import "./banner.scss";
import GetOffer from "../../common/getOffer";
import GetOfferSearchBar from "../../common/getOfferSearchBar";

export default class Banner extends Component {
  constructor(props) {
    super(props);
    this.getOfferElement = React.createRef();
  }
  state = {
    input: "",
    showOffers: false,
    accountType: 1
  };
  handleAccountTypeChange = e => {
    console.log('bannder',e.target.value)
    this.setState({ accountType: e.target.value });
  };
  
  handleInputChange = e => {
    this.setState({ input: e.target.value });
  };
  handleSubmit = e => {
    e.preventDefault();
    if (!this.state.input) return;

    this.getOfferElement.current.scrollIntoView({
      behavior: "smooth",
      block: "start"
    });
    this.setState({
      showOffers: true
    });
  };
  render() {
    const { input, showOffers } = this.state;
    const { ignoreSearches } = this.props;
    return (
      <section>
        <div
          id="banner"
          className="row flex-column align-content-center justify-content-center"
        >
          <div className="col-sm-9 col-xl-7  pb-4 align-self-center banner-text-container">
            <h2 className="col-md-12 pl-md-0 mb-0">INFLUENCER</h2>
            <h2 className="col-md-12 pl-md-0 mb-0">OFFER ENGINE</h2>
            {/* <h2 className="col-md-12 pl-md-0 mb-0" >MADE EASY</h2> */}
          </div>
          <div className="col-sm-9 col-md-8 col-xl-7 mx-auto">
            <div className="searchbar-wrapper">
              <GetOfferSearchBar
                input={input}
                handleInputChange={this.handleInputChange}
                handleSubmit={this.handleSubmit}
                accountType={Number(this.state.accountType)}
                handleAccountTypeChange={this.handleAccountTypeChange}
              />
            </div>
          </div>
        </div>
        <div ref={this.getOfferElement}>
          {showOffers || showOffers ? (
            <GetOffer
              name={input}
              hide={true}
              ignoreSearches={ignoreSearches}
              accountType={Number(this.state.accountType)}
              onAccountTypeChange={this.handleAccountTypeChange}
            />
          ) : null}
        </div>
      </section>
    );
  }
}
