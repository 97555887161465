import React from "react";

import "./samples.scss";

export default function Samples() {
  return (
    <section id="samples container">
      <div className="row  py-5">
        <div className="col-12">
          <div className="row py-2">
            <div className="col-lg-7 mx-lg-auto mx-3 embed-responsive embed-responsive-16by9 shadow">
              <iframe
                title="ad sample"
                src="https://www.youtube.com/embed/koO55zKwKn4?feature=oembed&start&end&wmode=opaque&loop=0&controls=1&mute=0&rel=0&modestbranding=0"
                className="embed-responsive-item"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-7 mx-auto">
          <div className="row d-flex justify-content-between py-2">
            <div className="p-youtube-embed col-lg-4 pl-lg-0 py-2">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  title="ad sample"
                  src="https://www.youtube.com/embed/_nqARr8E_HU?feature=oembed&start&end&wmode=opaque&loop=0&controls=1&mute=0&rel=0&modestbranding=0"
                  className="embed-responsive-item"
                />
              </div>
            </div>
            <div className="p-youtube-embed col-lg-4 py-2">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  title="ad sample"
                  src="https://www.youtube.com/embed/V24ce0Sjvc4?feature=oembed&start&end&wmode=opaque&loop=0&controls=1&mute=0&rel=0&modestbranding=0"
                  className="embed-responsive-item"
                />
              </div>
            </div>
            <div className="p-youtube-embed col-lg-4 pr-lg-0 py-2">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  title="ad sample"
                  src="https://www.youtube.com/embed/ygzR2tfkwqE?feature=oembed&start&end&wmode=opaque&loop=0&controls=1&mute=0&rel=0&modestbranding=0"
                  className="embed-responsive-item"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
