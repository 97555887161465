import React, { Component } from "react";

import "./criteriaDisplay.scss";

export default class CriteriaDisplay extends Component {
  render() {
    const {
      bonusType,
      bonus_percentage,
      bonus_upfront,
      budget,
      categories,
      comment,
      countries,
      likeToDislike,
      maxInfluencerPayout,
      minSubscribers,
      minViews,
      productsPerVideo
    } = this.props;
    return (
      <div id="dash-criteriaDisplay" className="container-fluid">
        <div className=" row bg-white rounded">
          {/* <div className="col-12 pt-5 pb-3 pl-md-5 pl-3">
            <h2 className="account-name">{minSubscribers}</h2>
          </div> */}
          <div className="col-12 d-flexflex-column py-3 pl-md-5 pl-3">
            <p className="account-property d-flex  flex-column align-items-start  flex-wrap ">
              <span className="account-key ">Min. avg. views per video</span>
              <span className="account-value shadow-sm text-uppercase">
                {minViews.toLocaleString()}
              </span>
            </p>
            <p className="account-property d-flex flex-column align-items-start flex-wrap">
              <span className="account-key ">Min. Subscribers</span>
              <span className="account-value shadow-sm text-uppercase">
                {minSubscribers.toLocaleString("en")}
              </span>
            </p>
            <p className="account-property d-flex flex-column align-items-start flex-wrap ">
              <span className="account-key">Budget</span>
              <span className="account-value shadow-sm">
                ${budget.toLocaleString("en")}
              </span>
            </p>
            <p className="account-property d-flex flex-column align-items-start flex-wrap ">
              <span className="account-key">Influencer max. payout</span>
              <span className="account-value shadow-sm">
                ${maxInfluencerPayout}
              </span>
            </p>
            <p className="account-property d-flex flex-column align-items-start flex-wrap ">
              <span className="account-key">Categories</span>
              <span className="account-value shadow-sm">
                {categories.reduce(
                  (prev, cur) => (prev += cur.label + ", "),
                  ""
                )}
              </span>
            </p>
            <p className="account-property d-flex flex-column align-items-start flex-wrap ">
              <span className="account-key">Countries</span>
              <span className="account-value shadow-sm">
                {countries.reduce(
                  (prev, cur) => (prev += cur.label + ", "),
                  ""
                )}
              </span>
            </p>
            <p className="account-property d-flex flex-column align-items-start flex-wrap ">
              <span className="account-key">Like to dislike ratio</span>
              <span className="account-value shadow-sm">{likeToDislike}%</span>
            </p>
            <p className="account-property d-flex flex-column align-items-start flex-wrap ">
              <span className="account-key">Bonus type</span>
              <span className="account-value shadow-sm">{bonusType.label}</span>
            </p>
            <p className="account-property d-flex flex-column align-items-start flex-wrap">
              <span className="account-key">Bonus percentage</span>
              <span className="account-value shadow-sm">
                {bonus_percentage}%
              </span>
            </p>
            {bonus_upfront && (
              <p className="account-property d-flex flex-column align-items-start flex-wrap ">
                <span className="account-key">Upfront bonus</span>
                <span className="account-value shadow-sm">
                  ${bonus_upfront}
                </span>
              </p>
            )}
            <p className="account-property d-flex flex-column align-items-start flex-wrap ">
              <span className="account-key">Products per video</span>
              <span className="account-value shadow-sm">
                {productsPerVideo}
              </span>
            </p>
            <p className="account-property d-flex flex-column align-items-start flex-wrap ">
              <span className="account-key">comment</span>
              <span className="account-value shadow-sm">
                {comment || "N/A"}
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
