import React, { Component } from "react";
import { Link } from "react-router-dom";
import { validate } from "../../utils/login.utils";

import "./signup.scss";
import RecursiveQuestions from "../common/recursiveQuestions";
import Err from "../common/error";
import { connect } from "react-redux";
import { signupInfluencer } from "../../redux/actions/auth.actions";
import { LinearProgress } from "@material-ui/core";
import Success from "../common/success";
import AddressForm from "../common/addressForm";
import CommonSignupQuestions from "../common/CommonSignupQuestions";
import FormField from "../common/formElements/formField";
import { getEstimate } from "../../utils/influencer.util";

class SignupInfluencer extends Component {
  state = {
    accountType: this.props.accountType,
    channelType: this.props.channelType,
    username: "",
    email: "",
    phone: "",
    password: "",
    password2: "",
    passwordMatch: null,
    address: "",
    address2: "",
    city: "",
    country: "United States",
    region: "",
    zip: "",
    accounts: [],
    name: "",
    validChannelId: null,
    questions: [
      {
        id: 1,
        question: "Do you own a blog",
        answer: "",
        required: true,
        options: ["yes", "no"],
        parentQuestionId: null,
        questions: [
          {
            id: 10001,
            question: "what is your blog name",
            condition: "yes",
            answer: "",
            required: true,
            parentQuestionId: 1,
            questions: []
          },
          {
            id: 10002,
            condition: "yes",
            answer: "",
            question: "can you add sponcered video to blog",
            parentQuestionId: 1,
            options: ["yes", "No"]
          }
        ]
      },
      {
        id: 2,
        question: "max number of products per video",
        answer: "",
        required: true
      }
      // {
      //   id: 3,
      //   question: "Primary channel type",
      //   answer: "",
      //   required: true,
      //   options: ["Youtube", "Instagram"],
      //   parentQuestionId: null,
      //   questions: [
      //     {
      //       id: 10003,
      //       question: "Youtube Channel ID",
      //       condition: "Youtube",
      //       answer: "",
      //       required: true,
      //       parentQuestionId: 1,
      //       questions: []
      //     },
      //     {
      //       id: 10004,
      //       question: "Instagram account name",
      //       condition: "Instagram",
      //       answer: "",
      //       required: true,
      //       parentQuestionId: 1,
      //       questions: []
      //     }
      //   ]
      // }
    ],
    showErrors: false,
    recursiveQuestionsErrors: false,
    error: false,
    errorPosition: null,
    message: null
  };

  componentDidMount() {
    let state = {};
    console.log(this.props.channelType,"signup channel type")
    // if coming from lead pipeline, we populate data already provided
    if (this.props.email) state.email = this.props.email;
    if (this.props.name) state.name = this.props.name;
    if (this.props.phone) state.phone = this.props.phone;
    if (this.props.channelId) {
      state.accounts = [];
      state.accounts.push({ id: this.props.channelId, platform: this.props.channelType });
    }

    if (Object.entries(state).length !== 0) {
      this.setState({ ...state });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.signupSuccess) {
      return {
        ...state,
        accountType: props.accountType,
        channelType: props.channelType,
        username: "",
        email: "",
        password: "",
        password2: "",
        phone: "",
        passwordMatch: null,
        address: "",
        address2: "",
        country: "United States",
        region: "",
        zip: "",
        name: "",
        accounts: [{ platform: "Youtube", id: "" }],
        showErrors: false,
        error: false,
        errorPosition: null,
        message: null,
        termsAccepted: false
      };
    }

    return {};
  }
  handleSubmit = e => {
    e.preventDefault();

    if (this.isMissingRequiredField()) {
      return this.setState({
        error: true,
        errorPosition: null,
        errorMessage: "Please Fill required fields",
        showErrors: true
      });
    } else if (this.state.error || this.state.recursiveQuestionsErrors) {
      return this.setState({
        errorPosition: null,
        showErrors: true
      });
    }

    this.props.signupInfluencer({ ...this.state }, this.props.history);
  };

  handleInputChange = e => {
    let obj = JSON.parse(JSON.stringify(this.state));

    // in some cases like address components, state is updated
    // in a different callback
    const shouldSetState = this._setAndValidateInputs(obj, e);

    shouldSetState && this.setState(obj);
  };

  handleSelectCountry = val => {
    this.setState({ country: val });
  };
  handleSelectRegion = val => {
    this.setState({ region: val });
  };

  handleQuestionsChange = (error, questions) => {
    this.setState({ recursiveQuestionsErrors: error, questions });
  };

  /**
   * passed to FormField for validating
   * channelID
   */
  validateId = async value => {
    // const estimate = await getEstimate(value);
    // if (estimate) {
    //   this.setState({ validChannelId: true });
    // } else {
    //   this.setState({ validChannelId: false });
    // }
    this.setState({ validChannelId: true });
  };

  isMissingRequiredField = () => {
    const {
      password,
      password2,
      passwordMatch,
      phone,
      email,
      name,
      username,
      country,
      region,
      address,
      city,
      accounts,
      termsAccepted
    } = this.state;
    if (
      !password ||
      !name ||
      !phone ||
      !email ||
      !username ||
      !password2 ||
      !passwordMatch ||
      !country ||
      !region ||
      !city ||
      !address ||
      !termsAccepted
    )
      return true;

    return false;
  };

  _setAndValidateInputs(obj, e) {
    let id = e.target.id;
    let value = e.target.value;
    let validation = { errorPosition: null };
    if (id === "") return false;

    if (id === "email") {
      obj.email = value;
    } else if (id === "name") {
      validation = validate("name", value);
      obj.name = value;
    } else if (id === "username") {
      validation = validate("username", value);
      obj.username = value;
    } else if (id === "phone") {
      obj.phone = value;
    } else if (id === "password") {
      validation = validate("password", value);
      obj.password = value;
    } else if (id === "password2") {
      let passwordMatch =
        value === "" ? null : this.state.password !== value ? false : true;
      obj.passwordMatch = passwordMatch;
      obj.password2 = value;
      if (!passwordMatch) {
        validation = { errorMessage: "passwords do not match", position: id };
      }
    } else if (id === "state") {
      obj.region = value;
    } else if (id === "address") {
      obj.address = value;
    } else if (id === "address2") {
      obj.address2 = value;
    } else if (id === "city") {
      obj.city = value;
    } else if (id === "zipcode") {
      obj.zip = value;
    } else if (id === "agreeToTerms") {
      if (e.target.checked) {
        obj.termsAccepted = true;
      } else {
        obj.termsAccepted = false;
      }
    } else if (id === "channelId") {
      if (value) {
        obj.accounts = [
          {
            platform: "Youtube",
            id: value
          }
        ];
      } else {
        obj.accounts = [];
      }
    } else {
      return false;
    }

    if (typeof validation.errorMessage === "string") {
      obj.error = true;
      obj.errorMessage = validation.errorMessage;
      obj.errorPosition = validation.position;
    } else {
      obj.error = false;
      obj.errorMessage = "";
      obj.errorPosition = null;
    }

    return obj;
  }

  render() {
    const { signupLoading, signupSuccess, signupError } = this.props;
    const {
      error,
      showErrors,
      errorMessage,
      errorPosition,
      questions,
      country,
      region,
      name,
      phone,
      email,
      password,
      password2,
      passwordMatch,
      username,
      address,
      address2,
      city,
      zip,
      accounts,
      termsAccepted,
      validChannelId
    } = this.state;

    return (
      <div id="signup" className="py-md-5 pb-5">
        <h3 className="h1 text-muted lead">Influencer Signup</h3>
        <form
          className="signup-form mx-auto my-md-5 pt-4 text-left"
          onSubmit={this.handleSubmit}
          onChange={this.handleInputChange}
        >
          <CommonSignupQuestions
            username={username}
            name={name}
            password={password}
            password2={password2}
            email={email}
            passwordMatch={passwordMatch}
            showErrors={showErrors}
            error={error}
            errorPosition={errorPosition}
            errorMessage={errorMessage}
            phone={phone}
          />

          <FormField
            id="channelId"
            value={accounts[0] ? accounts[0].id : ""}
            question="Youtube channel ID"
            required={false}
            showErrors={showErrors}
            error={error}
            errorPosition={errorPosition}
            errorMessage={errorMessage}
            validationFunction={this.validateId}
            valid={validChannelId}
          />

          <p className="lead text-muted text-center mb-4 pt-3 mt-5">Address</p>
          <AddressForm
            address={address}
            country={country}
            address2={address2}
            region={region}
            zip={zip}
            city={city}
            showErrors={showErrors}
            handleInputChange={this.handleInputChange}
            handleSelectCountry={this.handleSelectCountry}
            handleSelectRegion={this.handleSelectRegion}
          />

          <p className="lead text-muted text-center mb-4 pt-3 mt-5">
            More Details
          </p>
          <RecursiveQuestions
            questions={questions}
            showErrors={showErrors}
            handleQuestionsChange={this.handleQuestionsChange}
          />

          <div
            className={`form-group pt-4 mb-0 form-check ${showErrors &&
              !termsAccepted &&
              "form-control-error"}`}
          >
            <input
              type="checkbox"
              className="form-check-input"
              id="agreeToTerms"
            />
            <label className={`form-check-label`} htmlFor="agreeToTerms">
              Check if you agree to{" "}
              <a
                href="/docs/terms/termsAgreement-7-10-2019.pdf"
                target="_blank"
              >
                terms of service
              </a>
            </label>
          </div>

          <button type="submit" className="btn btn-primary w-100 mt-4">
            Signup
          </button>
          {error && showErrors && (
            <Err title="Fix errors before submiting" message={errorMessage} />
          )}
          {!error && signupLoading && <LinearProgress />}
          {!error && signupSuccess && (
            <Success message="signed up successfully! now go login" />
          )}
          {!error && signupError && <Err message={signupError} />}
        </form>
        <hr className="w-75" />
        <p className="text-muted">
          Already have an account? <Link to="/login">click here to Login</Link>
        </p>
      </div>
    );
  }
}

const mapState = state => {
  return {
    signupLoading: state.auth.signupLoading,
    signupSuccess: state.auth.signupSuccess,
    signupError: state.auth.signupError
  };
};

const mapDispatch = {
  signupInfluencer
};

export default connect(
  mapState,
  mapDispatch
)(SignupInfluencer);
