import React from "react";
import PropTypes from "prop-types";
import LinearProgress from "@material-ui/core/LinearProgress";

import "./getOfferSearchBar.scss";

export default function GetOfferSearchBar({
  input,
  handleInputChange,
  handleSubmit,
  loading,
  accountType,
  handleAccountTypeChange
}) {
  return (
    <form id="get-offer-form" className="getoffer-form" onSubmit={handleSubmit}>
      <input
        type="text"
        className="getoffer-input shadow border-0 "
        placeholder="enter channel name..."
        onChange={handleInputChange}
        value={input}
        id="textInput"
      />
      <select 
      onChange={handleAccountTypeChange}
      value={accountType}
      id="iconSelect">
                <option value={1}>Youtube</option>
                <option value={2}>Instagram</option>
                <option value={3}>TikTok</option>
    </select>
      {/* eslint-disable-next-line  */}
      <input
        type="submit"
        className="btn btn-primary text-white shadow"
        value="GET OFFER"
      />
      {loading && (
        <div className=" linearProgress mx-auto px-lg-0">
          <LinearProgress />
        </div>
      )}
    </form>
  );
}

GetOfferSearchBar.propTypes = {
  input: PropTypes.string.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  accountType: PropTypes.number.isRequired,
  handleAccountTypeChange: PropTypes.func.isRequired
};
