import countries from "./countries.json";

export const clone = obj => {
  return JSON.parse(JSON.stringify(obj));
};

/**
 * This function compares two arrays (of objects) and returns and array containing the
 * indexes of new items in newArray.
 *
 * @param {Array} newArray new data array
 * @param {Array} oldArray old data array
 * @param {String} decidingProperty name of propetry that decides if object in the array is new
 */
export const findNewElements = (newArray, oldArray, decidingProperty) => {
  const newElementsIndexes = [];

  for (let i = 0; i < newArray.length; i++) {
    let found = false;
    for (let j = 0; j < oldArray.length; j++) {
      if (newArray[i][decidingProperty] === oldArray[j][decidingProperty]) {
        found = true;
      }
    }
    if (!found) {
      newElementsIndexes.push(i);
    }
  }

  return newElementsIndexes;
};

export const withinLast24Hours = date => {
  const oneDay = 1 * 24 * 60 * 60 * 1000;
  const dayAgo = Date.now() - oneDay;

  return date > dayAgo;
};

/**
 * takes two dates and compares the first one against the second and returns
 * true if it more recent
 * @param {String|Date} newDate date to compare
 * @param {String|Date} existingDate date to compare against
 * @returns {Boolean}
 */
export const isMoreRecentDate = (newDate, existingDate) => {
  if (new Date(newDate).getTime() - new Date(existingDate).getTime() > 0) {
    return true;
  }

  return false;
};

/**
 * returns a list of countries formated for react-select
 * component
 */
export const countriesOptions = () => {
  return countries.map(country => ({
    value: country.name,
    label: country.name
  }));
};
