import React, { Component } from "react";
import withLogin from "../common/HOC/withLogin";

import "./aboutus.scss";

class AboutUs extends Component {
  render() {
    return (
      <section id="aboutus" className="container pt-lg-5 pb-4 my-lg-5">
        {/* <h2 className="display-4 text-light p-4 px-5 rounded mx-right mb-5 page-title">
          About Us
        </h2> */}
        <div className="row bg-light shadow aboutus-section">
          <div className="col-lg-6 text-left pt-5 px-3 p-lg-5 vertical-align">
            <h3 className="display-4 pb-4 p-title">Welcome!</h3>
            <p className="lead text-muted">
              We are a group of creatives that knows the value of your amazing
              content, and how hard it is to develop it! We work hard to connect
              positive, high-impact influencers with high-integrity brands that
              understand the value of a great influencer. We specialize in
              influencer marketing campaign strategy and excecution, performance
              marketing, paid social, and integrated brand strategy.{" "}
            </p>
          </div>
          <div className="col-lg-6 px-lg-0 py-3 py-lg-0 text-right">
            <img src="/imgs/welcome.jpg" alt="welcome" className="img-fluid" />
          </div>
        </div>
        <div className="row why-ziigg my-5 d-block">
          <div className="why-ziigg-overlay" />
          <div className="py-5 px-lg-5">
            <h2 className="display-4 why-ziigg-title mb-0">Why Ziigg?</h2>
            <p className="lead why-ziigg-subtitle">
              LET US HELP YOU MONETIZE YOUR HARD WORK.
            </p>
            <div className="my-5 px-3 px-lg-4 text-muted text-left font-weight-light lead why-ziigg-text">
              <p>
                You’ve probably heard people say, “I guess I zagged when I
                should have zigged.” That sounds like a harmless little phrase,
                but it’s really an expression of regret about random choices
                that led in a specific direction. Life is full of seemingly
                random choices that are actually life-directing decisions, so
                making good choices – even when they seem small and
                insignificant – is a very important part of any person’s
                success.
              </p>
              <p>
                We want to help you make good choices about how you leverage
                your hard work and time spent developing your unique content. We
                are a group of professionals and creatives that have business
                development and marketing skills stretching back over 20+ years.
                We have connections with many top brands and are able to offer
                high-paying influencer opportunities, We can help you maximize
                your energy and create multiple revenue streams while providing
                you with technology and tools that will build your overall
                income.
              </p>
              <p>
                We are a boutique service focusing on high-end quality content,
                as well as high-character individuals who are trying to make a
                difference while providing for themselves and their families.
                Our brands pay a premium to high quality influencers, and if you
                qualify, we would love to have you on our team!
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default AboutUs;
// export default withLogin("influencer")(AboutUs);
