import React from "react";

import "./opportunities.scss";

export default function Opportunities() {
  return (
    <section id="opportunities" className="py-5 my-5 ">
      <div className="container pb-5 pt-3">
        <div className="row pb-3">
          <div className="col text-center">
            <h3>Opportunities</h3>
            <p className="lead text-muted">
              brands currently available for marketing - click on a product for
              more details
            </p>
          </div>
        </div>
        <div className="row">
          {companies &&
            companies.map(
              ({
                name,
                logo,
                picture,
                discription,
                logoStyle,
                imageStyle,
                link
              }) => (
                <div key={name} className="col-lg-4 py-3 ">
                  <div className="card rounded-0 border-0">
                    <a href={link} target="_blank" rel="noopener noreferrer">
                      <div className="card-img-top">
                        <img
                          className="p-card-img"
                          src={picture}
                          style={imageStyle ? imageStyle : {}}
                          alt="Card cap"
                        />
                        <div className="logo-overlay">
                          <img
                            src={logo}
                            alt={name}
                            style={logoStyle ? logoStyle : {}}
                            className="logo"
                          />
                        </div>
                      </div>
                    </a>
                    <div className="card-body px-0">
                      <p className="card-text text-black-50 text-left">
                        {discription}
                      </p>
                    </div>
                  </div>
                </div>
              )
            )}
        </div>
      </div>
    </section>
  );
}

let companies = [
  {
    item: 1,
    name: "luminesce",
    link: "https://www.jeunesseglobal.com/en-US/luminesce",
    logo: "/imgs/opportunities/luminesce-logo.png",
    picture: "/imgs/opportunities/luminesce-skin-care.png",
    discription:
      "Dermatologist-developed, the Luminesce® skin care line reduces the appearance of fine lines and wrinkles and restores youthful vitality and radiance to your skin. "
  },
  {
    item: 2,
    name: "instantly-ageless",
    link: "https://www.jeunesseglobal.com/en-US/instantly-ageless",
    logo: "/imgs/opportunities/instantly-ageless-logo.png",
    picture: "/imgs/opportunities/instantly-ageless-box-vial-500.png",
    discription:
      "Instantly Ageless immediately tightens and smooths the skin’s appearance, effectively reducing the look of under-eye bags, fine lines, wrinkles and enlarged pores."
  },
  {
    item: 3,
    name: "nv primer",
    link: "https://www.jeunesseglobal.com/en-US/nv",
    logo: "/imgs/opportunities/nv-logo-300x172.png",
    picture:
      "/imgs/opportunities/nv-skin-primer-foundation-bronzer-600-231x300.png",
    logoStyle: { maxWidth: "50%" },
    discription:
      "Formulated with exclusive, youth-enhancing APT-200™, NV™ includes a skin-perfecting primer, foundation and bronzer that give you an enviable, professional airbrush finish."
  },
  {
    item: 4,
    name: "volugio",
    link:
      "https://www.adseyewear.com/Sunglasses/2/Non-Prescription/5/Volugio/214",
    logo: "/imgs/opportunities/Volugio-Logo-White.png",
    logoStyle: { maxWidth: "50%" },
    picture: "/imgs/opportunities/volugio-prescription-sunglasses-300x203.jpg",
    discription:
      "Volugio  is a boutique eyewear company specializing in prescription sports sunglasses and other eyewear for active people. Their Free-Form Digital prescription lenses are the best Rx lenses for sports on the planet."
  },
  {
    item: 5,
    name: "MEDIGAP",
    link: "https://1800medigap.com/",
    logo: "/imgs/opportunities/1800MEDIGAP.png",
    logoStyle: { maxWidth: "50%" },
    picture: "/imgs/opportunities/medigap-man.jpeg",
    imageStyle: { transform: "translateY(-25px)" },
    discription:
      "Retirement can be confusing. Our professionals are trained, certified and licensed by your State Department of Insurance to assist our customers by providing qualified help when and how they need it."
  },
  {
    item: 6,
    name: "zen jeunesse",
    link: "https://www.jeunesseglobal.com/en-US/zen",
    logo: "/imgs/opportunities/zen-logo-300x133.png",
    logoStyle: { maxWidth: "50%" },
    picture: "/imgs/opportunities/Zen-weight-management-supplement-300x213.png",
    discription:
      "The ZEN product line is scientifically formulated with premium ingredients found in nature that work synergistically to eliminate toxins, feed your body, and help you achieve your weight management goals."
  },
  {
    item: 7,
    name: "rvl jeunesse",
    link: "https://www.jeunesseglobal.com/en-US/rvl",
    logo: "/imgs/opportunities/rvl-logo.png",
    picture: "/imgs/opportunities/RVL-luxury-hair-care-300x300.jpg",
    discription:
      "RVL is a luxury at-home hair care system that includes botanicals like fenugreek, flaxseed oil and jojoba oil. Each product works to help gently revitalize the appearance of your hair and scalp for fuller looking hair."
  },
  {
    item: 8,
    name: "naara jeunesse",
    link: "https://www.jeunesseglobal.com/en-US/naara",
    logo: "/imgs/opportunities/naara-logo.png",
    picture: "/imgs/opportunities/naara-collagen-building-supplement.png",
    discription:
      "Skin care you can drink, Naära™ provides 5,000 mg TruMarine™ Collagen* to help replenish skin’s natural loss of collagen over time."
  },
  {
    item: 9,
    name: "revitablu jeunesse",
    link: "https://www.jeunesseglobal.com/en-US/revitablu",
    logo: "/imgs/opportunities/revitablu-logo-white-150x150.png",
    logoStyle: { maxWidth: "50%" },
    picture: "/imgs/opportunities/revitablu-stem-cell-supplement-300x300.png",
    discription:
      "Formulated by a pioneer in stem cell research, RevitaBlū is a botanical blend of blue-green algae, sea buckthorn berry and aloe vera with coconut water powder. It’s a refreshing drink mix that tastes as great as it works."
  }
];
