import Jwt from "jsonwebtoken";
import {
  LOGOUT,
  LOGIN_ERROR,
  SIGNUP_LOADING,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  REFRESH_TOKEN
} from "../types";
const defaultState = {
  userId: 0,
  loggedIn: false,
  token: "",
  accountType: null,
  signupLoading: false,
  signupSuccess: false,
  signupError: false,
  username: ""
};

export default (state = defaultState, action) => {
  state = { ...state };
  const { type, payload } = action;
  if (type === LOGIN_LOADING) {
    return {
      ...state,
      loginLoading: true,
      loginSuccess: false,
      loginError: false
    };
  } else if (type === LOGIN_SUCCESS) {
    const jwtPayload = Jwt.decode(payload.token);
    return {
      ...state,
      loginLoading: false,
      loginSuccess: true,
      loginError: false,
      loggedIn: true,
      token: payload.token,
      accountType: jwtPayload.user_type.type,
      username: jwtPayload.username,
      name: jwtPayload.name,
      email: jwtPayload.email,
      phone: jwtPayload.phone,
      country: jwtPayload.country,
      expires_on: jwtPayload.exp,
      userId: jwtPayload.id
    };
  } else if (type === LOGIN_ERROR) {
    return {
      ...state,
      loginLoading: false,
      loginSuccess: false,
      loginError: payload.message || "username or password is incorrect"
    };
  } else if (type === SIGNUP_LOADING) {
    return {
      ...state,
      signupLoading: true,
      signupError: false,
      signupSuccess: false
    };
  } else if (type === SIGNUP_SUCCESS) {
    return {
      ...state,
      signupLoading: false,
      signupError: false,
      signupSuccess: true
    };
  } else if (type === SIGNUP_ERROR) {
    return {
      ...state,
      signupLoading: false,
      signupError: payload.message || "signup failed",
      signupSuccess: false
    };
  } else if (type === LOGOUT) {
    return {
      ...defaultState
    };
  } else if (type === REFRESH_TOKEN) {
    const jwtPayload = Jwt.decode(payload.token);

    return {
      ...state,
      token: payload.token,
      expires_on: jwtPayload.exp
    };
  }
  return state;
};
