import React from "react";
import CountUp from "react-countup";
import CircularProgress from "@material-ui/core/CircularProgress";

import "./dashCard.scss";

export default function DashCard({
  color,
  title,
  body,
  meta,
  loading,
  formattingFn
}) {
  return (
    <div
      id="dashCard"
      className={`d-flex flex-column px-3 px-md-5 py-4 text-white dashcard-${color ||
        ""}`}
    >
      <p className="dashcard-title mb-1">{title}</p>
      <div className="dashcard-body mb-4">
        {loading ? (
          <CircularProgress color="inherit" />
        ) : formattingFn ? (
          <CountUp formattingFn={formattingFn} end={body} duration={2} />
        ) : (
          <CountUp
            end={body}
            formattingFn={num => num.toLocaleString()}
            duration={2}
          />
        )}
      </div>
      <p className="dashcard-meta mb-1">{meta}</p>
    </div>
  );
}
