export const LOGOUT = "LOGOUT";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const SIGNUP_LOADING = "SIGNUP_LOADING";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_ERROR = "SIGNUP_ERROR";
export const REFRESH_TOKEN = "REFRESH_TOKEN";

/* influencer */
export const RESERVE_LOADING = "RESERVE_LOADING";
export const RESERVE_SUCCESS = "RESERVE_SUCCESS";
export const RESERVE_ERROR = "RESERVE_ERROR";
export const ADD_ACCOUNTS = "ADD_ACCOUNTS";
