import React, { Component } from "react";

import "./adminLeftNavbar.scss";

export default class InfluencerLeftNavbar extends Component {
  render() {
    const { currentSelected = "dash-offers" } = this.props;
    return (
      <nav id="dash-left-navbar">
        <ul className="nav flex-column">
          <li
            data-section="dash-leads"
            onClick={this.props.handleSectionClick}
            className="nav-item"
          >
            <a
              className={`nav-link mb-2 ${currentSelected === "dash-leads" &&
                "active"}`}
              href="#"
            >
              Leads
            </a>
            <i className="fas fa-comment-dollar" />
          </li>
          <li
            data-section="dash-brands"
            onClick={this.props.handleSectionClick}
            className="nav-item"
          >
            <a
              className={`nav-link mb-2 ${currentSelected === "dash-brands" &&
                "active"}`}
              href="#"
            >
              Brands
            </a>
            <i className="far fa-clipboard" />
          </li>
          <li
            data-section="dash-reports"
            onClick={this.props.handleSectionClick}
            className="nav-item"
          >
            <a
              className={`nav-link mb-2 ${currentSelected === "dash-reports" &&
                "active"}`}
              href="#"
            >
              Reports
            </a>
            <i className="fas fa-chart-line" />
          </li>
          <li
            data-section="dash-banking"
            onClick={this.props.handleSectionClick}
            className="nav-item"
          >
            <a
              className={`nav-link mb-2 ${currentSelected === "dash-banking" &&
                "active"}`}
              href="#"
            >
              Banking
            </a>
            <i className="fas fa-university" />
          </li>
          <li
            data-section="dash-team"
            onClick={this.props.handleSectionClick}
            className="nav-item"
          >
            <a
              className={`nav-link mb-2 ${currentSelected === "dash-team" &&
                "active"}`}
              href="#"
            >
              Team
            </a>
            <i className="fas fa-users-cog" />
          </li>
          <li
              data-section="dash-redirects"
              onClick={this.props.handleSectionClick}
              className="nav-item"
          >
            <a
                className={`nav-link mb-2 ${currentSelected === "dash-redirects" &&
                "active"}`}
                href="#"
            >
              Redirects
            </a>
            <i className="fas fa-users-cog" />
          </li>
        </ul>
      </nav>
    );
  }
}
