import React from "react";
import PropTypes from "prop-types";

import "./sectionTitle.scss";

export default function SectionTitle({ data }) {
  return <h2 className="section-title">{data}</h2>;
}

SectionTitle.prototype = {
  data: PropTypes.string.isRequired
};
