import React, { Component } from "react";

import "./connect.scss";

export default class Connect extends Component {
  render() {
    return (
      <section id="connect" className="container px-0">
        <div className="jumbotron text-left px-4 px-lg-5">
          <h3 className="display-4">Like to Connect?</h3>
          <p className="lead text-muted">
            Shoot us an email and we'll contact you shortly. We look forward to
            talking with you!
          </p>
          <hr className="my-4" />
          <a
            className="btn btn-primary btn-lg p-btn-gradient shadow"
            href="mailto:jeff.cline@me.com"
            role="button"
          >
            Email Ziigg
          </a>
        </div>
      </section>
    );
  }
}
