import React from "react";

import "./success.scss";

export default function Success({ title, message }) {
  if (title && message) {
    return (
      <div className="alert alert-success">
        <h5 className="success-title">{title}</h5>
        <p className="success-message">{message}</p>
      </div>
    );
  }
  if (message || title) {
    return <div className="alert alert-success">{message || title}</div>;
  }

  return <div className="alert alert-success">Success!</div>;
}
