/**
 * Using React hooks in this component.
 */
import React, { useState } from "react";

import { Formik, Form, Field, ErrorMessage } from "formik";
import { CircularProgress } from "@material-ui/core";
import * as yup from "yup";

import Success from "../../../common/success";
import axios from "../../../../utils/axios";
import configs from "../../../../configs/envVariables.js";
import Err from "../../../common/error";

const ApiBaseURI = configs.REACT_APP_API_DOMAIN;

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .min(2, "Name is too short!")
    .max(50, "Name is too long!")
    .required(),
  email: yup
    .string()
    .email()
    .required(),
  accountType: yup
    .string()
    .oneOf(["admin", "brands_rep", "influencer_rep"])
    .required(),
  phone: yup.string().required(),
  region: yup.string().required(),
  country: yup.string().required()
});

const initialValues = {
  name: "",
  email: "",
  accountType: "",
  phone: "",
  region: "",
  comment: "",
  country: ""
};

export default ({ handleNewMember }) => {
  const [success, setSuccess] = useState(false);
  const [submitError, setSubmitError] = useState({ error: false, message: "" });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting, resetForm, setFieldError }) => {
        try {
          setSubmitting(true);
          await axios.post(`${ApiBaseURI}/admin`, {
            ...values,
            city: values.region,
            username: values.email,
            address1: "random",
            address2: "",
            zip: "",
            questions: []
          });

          setSuccess(true);
          setSubmitError({ error: false, message: "" });
          handleNewMember(values);
          resetForm(initialValues);
        } catch (err) {
          const errorMessage =
            err.response && err.response.data
              ? err.response.data.error
              : err.message;
          setSubmitError({ error: true, message: errorMessage });
        }
        setSubmitting(false);
      }}
    >
      {({ isSubmitting }) => {
        return (
          <Form id="team-member-form">
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="name">Name</label>
                <Field
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder="Full name"
                />
                <ErrorMessage name="name">
                  {msg => <span className="text-danger">{msg}</span>}
                </ErrorMessage>
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="email">Email</label>
                <Field
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder="Email"
                />
                <ErrorMessage name="email">
                  {msg => <span className="text-danger">{msg}</span>}
                </ErrorMessage>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label className="mr-sm-2" htmlFor="account-type">
                  Account Type
                </label>
                <Field
                  component="select"
                  className="custom-select mr-sm-2"
                  id="account-type"
                  name="accountType"
                >
                  <option value="">Choose Type</option>
                  <option value="admin">Admin</option>
                  <option value="brand_rep" disabled>
                    Brands Rep (soon)
                  </option>
                  <option value="influencer_rep" disabled>
                    Influencers Rep (soon)
                  </option>
                </Field>
                <ErrorMessage name="accountType">
                  {msg => <span className="text-danger">{msg}</span>}
                </ErrorMessage>
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="phone">Phone</label>
                <Field
                  type="phone"
                  className="form-control"
                  id="phone"
                  name="phone"
                  placeholder="Phone"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="password">Password</label>
                <Field
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  placeholder="Password"
                />
                <ErrorMessage name="password">
                  {msg => <span className="text-danger">{msg}</span>}
                </ErrorMessage>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="region">Region</label>
              <Field
                type="text"
                className="form-control"
                id="region"
                name="region"
                placeholder="Singapore"
              />
              <ErrorMessage name="region">
                {msg => <span className="text-danger">{msg}</span>}
              </ErrorMessage>
            </div>
            <div className="form-group">
              <label htmlFor="city">Country</label>
              <Field
                type="text"
                className="form-control"
                id="country"
                name="country"
                placeholder="Singapore"
              />
              <ErrorMessage name="country">
                {msg => <span className="text-danger">{msg}</span>}
              </ErrorMessage>
            </div>
            <div className="form-group">
              <label htmlFor="comment">Comment</label>
              <Field
                component="textarea"
                className="form-control"
                id="comment"
                name="comment"
                placeholder="add a comment about team memeber here.."
              />
              <ErrorMessage name="comment">
                {msg => <span className="text-danger">{msg}</span>}
              </ErrorMessage>
            </div>
            <button
              type="submit"
              className="btn btn-primary mr-2 shadow-sm"
              disabled={isSubmitting}
            >
              Submit Member
            </button>
            {isSubmitting && (
              <span style={{ color: "#007bff" }}>
                <CircularProgress color="inherit" size="23px" />
              </span>
            )}
            {success && (
              <div className="py-2">
                <Success message="Team member was added successfully!" />
              </div>
            )}
            {submitError.error && <Err message={submitError.message} />}
          </Form>
        );
      }}
    </Formik>
  );
};
