/**
 * TODOS:
 *
 * allow new leads to be marked orange for longer than
 * the refetch rate.
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import SectionTitle from "../../../common/dashboard/elements/sectionTitle";
import moment from "moment";

import { refreshToken, logout } from "../../../../redux/actions/auth.actions";
import "./leads.scss";
import axios from "../../../../utils/axios";
import {
  clone,
  findNewElements,
  withinLast24Hours,
  isMoreRecentDate
} from "../../../../utils/utils";
import { LinearProgress, CircularProgress } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import configs from "../../../../configs/envVariables.js";

const ApiBaseURI = configs.REACT_APP_API_DOMAIN;

class Leads extends Component {
  state = {
    loading: true,
    originalLead: [],
    leads: [],
    sortBy: "sort-by",
    clickedLeadId: null,
    deleteLoading: false,
    timeFromLastFetch: "now",
    sorts: [
      ["Sort by", "sort-by"],
      ["Most recent", "most-recent"],
      ["Oldest first", "oldest"]
    ]
  };
  componentDidMount() {
    this.fetchLeads({ markNew: false });
    this.setFetchLeadsInterval();
    this.setTimeFromLastFetch();
  }

  componentWillUnmount() {
    clearInterval(this.fetchLeadsInterval);
    clearInterval(this.timeFromLastFetchInterval);
  }

  handleSortBy = e => {
    const sortby = e.target.dataset.sortby;
    let leads = [...this.state.leads];

    if (sortby === "most-recent") {
      leads = this.sortLeadsByDate(this.state.leads, true);
    } else if (sortby === "oldest") {
      leads = this.sortLeadsByDate(this.state.leads, false);
    }

    this.setState({ leads, sortBy: sortby });
  };

  /**
   * TODO: since data is aggregated then user is
   * having to delete one channel multiple times to erase it
   * if it was search more than once.
   * To fix this we need to use originalLead instead and grap
   * all id's of similar leads and delete them all using Promise.all.
   */
  handleDelete = e => {
    let channelId = e.currentTarget.dataset.channelid;
    this.setState({ deleteLoading: true }, async () => {
      try {
        await axios.delete(`${ApiBaseURI}/search/${channelId}`);
      } catch (err) {
        // TODO handle error
        console.error(err);
        this.setState({ deleteLoading: false });
      }
      this.fetchLeads({ markNew: false });
    });
  };
  handleLeadClick = e => {
    let channelId = parseInt(e.currentTarget.dataset.channelid);
    this.setState({ clickedLeadId: channelId });
  };

  handleTimeFromClick = () => {
    this.setState(
      {
        timeFromLastFetch: "now"
      },
      () => {
        this.fetchLeads();
      }
    );
  };

  /**
   * calls fetchLeads and sets interval for calling it
   */
  setFetchLeadsInterval = () => {
    let fetchCounter = 0;
    this.fetchLeadsInterval = setInterval(() => {
      if (fetchCounter && fetchCounter % 30 === 0) {
        fetchCounter++;
        return this.props.refreshToken();
      }

      fetchCounter++;

      // stop auto fetching after 2 hours (7200000 ms / 15000)
      if (fetchCounter >= 240) return;

      this.fetchLeads();
    }, 15000);
  };

  fetchLeads = ({ markNew } = { markNew: true }) => {
    axios
      .get(`${ApiBaseURI}/search`)
      .then(res => {
        let leads = res.data;
        leads = this.aggregateLeads(leads);
        // on first data fetch we don't need new leads
        if (markNew) leads = this.markNewLeads(leads);
        leads = this.markTodaysLeads(leads);
        leads = this.sortLeads(leads);

        this.setState({
          leads,
          originalLead: res.data,
          loading: false,
          deleteLoading: false,
          lastFetched: moment()
        });
      })
      .catch(err => {
        if (err.status === 401) {
          this.props.logout();
        }
        console.error(err);
      });
  };

  setTimeFromLastFetch = () => {
    this.timeFromLastFetchInterval = setInterval(() => {
      const { lastFetched } = this.state;

      this.setState({ timeFromLastFetch: moment(lastFetched).fromNow() });
    }, 5000);
  };

  /**
   * gits rid of duplicate leads & ensures only most
   * up to date version of each lead is kept
   */
  aggregateLeads = leads => {
    const resultSet = new Map();
    const result = [];

    leads.forEach(lead => {
      if (!resultSet.has(lead.Search)) {
        result.push(lead);
        resultSet.set(lead.Search, {
          create_time: lead.CreateTime,
          index: result.length - 1
        });
      } else if (
        isMoreRecentDate(
          lead.CreateTime,
          resultSet.get(lead.Search).CreateTime
        )
      ) {
        let itemIndex = resultSet.get(lead.Search).index;
        result.splice(itemIndex, 1, lead);
        resultSet.set(lead.Search, {
          create_time: lead.CreateTime,
          index: itemIndex
        });
      }
    });

    return result;
  };

  sortLeads = leads => {
    const { sorts } = this.state;
    const { sortBy } = this.state;

    if (sortBy === sorts[0][1] || sortBy === sorts[1][1])
      leads = this.sortLeadsByDate(leads);

    if (sortBy === sorts[2][1]) leads = this.sortLeadsByDate(leads, false);

    return leads;
  };
  /**
   * @param {BOOLEAN} bool if true it sorts largest/closest first
   */
  sortLeadsByDate = (leads, bool = true) => {
    leads = [...leads];
    return leads.sort(function(a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      if (bool) {
        return moment(b.create_time).toDate() - moment(a.create_time).toDate();
      } else {
        return moment(a.create_time).toDate() - moment(b.create_time).toDate();
      }
    });
  };

  /**
   * adds "new" property to new leads
   * TODO: fix - when fetching intervally it is marking leads
   * as new when they are not.
   */
  markNewLeads = newLeads => {
    newLeads = clone(newLeads);
    const newElementsIndexes = findNewElements(
      newLeads,
      this.state.leads,
      "id"
    );
    newElementsIndexes.forEach(index => {
      newLeads[index].new = true;
    });

    return newLeads;
  };

  markTodaysLeads = newLeads => {
    newLeads.forEach(lead => {
      if (
        withinLast24Hours(
          moment(lead.CreateTime)
            .toDate()
            .getTime()
        )
      ) {
        lead.newToday = true;
      }
    });

    return newLeads;
  };

  render() {
    const {
      leads,
      sortBy,
      loading,
      clickedLeadId,
      deleteLoading,
      sorts,
      timeFromLastFetch
    } = this.state;
    return (
      <div id="dash-leads" className="container-fluid">
        <div className="row pb-4">
          <div className="col-12">
            <SectionTitle data="Leads" />
          </div>
        </div>
        <div className="row bg-white px-md-3 py-md-2 rounded">
          <div className="col-12 py-3 d-flex justify-content-between align-items-center">
            <div
              className="time-from-container mr-3"
              onClick={this.handleTimeFromClick}
            >
              <span className="time-from px-3 py-1 rounded">
                updated {timeFromLastFetch}
              </span>
            </div>
            <div className="dropdown show" onClick={this.handleSortBy}>
              <span
                className="btn dropdown-toggle"
                href="#"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {sorts.find(sort => sort[1] === sortBy)
                  ? sorts.find(sort => sort[1] === sortBy)[0]
                  : sorts[0][0]}
              </span>

              <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                {sorts.map((sort, i) => {
                  if (i) {
                    return (
                      <span
                        key={i}
                        className="dropdown-item"
                        data-sortby={sort[1]}
                        href="#"
                      >
                        {sort[0]}
                      </span>
                    );
                  }
                })}
              </div>
            </div>
          </div>

          <div className="col-12 rounded">
            <ul className="list-group pb-5">
              {leads.map((lead, index) => {
                return (
                  <li
                    key={lead.Search}
                    data-channelid={lead.ID}
                    className={`list-group-item py-3 d-flex flex-wrap align-items-center ${lead.new &&
                      "new-lead"} ${lead.newToday && "new-lead-today"}`}
                    onClick={this.handleLeadClick}
                  >
                    <span className="list-item-key lead">
                      Channel:{" "}
                      <span className="list-item-value text-sm">
                        {lead.Search}
                      </span>
                    </span>
                    <span className="list-item-key lead ">
                      Date:{" "}
                      <span className="list-item-value text-bold">
                        {moment(lead.CreateTime)
                          .toDate()
                          .toLocaleDateString()
                          .replace(/\//g, "-")}
                      </span>
                    </span>
                    <span className="list-item-key lead ">
                      Location:{" "}
                      <span className="list-item-value text-bold">
                        {lead.Region || lead.City || lead.IP || "n/a"}
                      </span>
                    </span>
                    <span className="list-item-key lead ">
                      Referrer:{" "}
                      <span className="list-item-value text-bold">
                        {lead.User || "n/a"}
                      </span>
                    </span>
                    <span
                      className={`list-item-key delete-icon d-flex justify-content-end pt-4 pt-md-1 ${clickedLeadId ===
                        lead.ID && "clicked"}`}
                    >
                      {deleteLoading && clickedLeadId === lead.ID ? (
                        <CircularProgress
                          style={{ color: "rgb(156, 185, 199)" }}
                        />
                      ) : (
                        <DeleteIcon
                          data-channelid={lead.ID}
                          onClick={this.handleDelete}
                        />
                      )}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="row">
          {loading && (
            <div className="col-12 p-0">
              <LinearProgress />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatch = {
  refreshToken,
  logout
};
export default connect(
  null,
  mapDispatch
)(Leads);

moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "%ds",
    ss: "%d seconds",
    m: "1m",
    mm: "%dm",
    h: "1 h",
    hh: "%d hours",
    d: "1 d",
    dd: "%d days",
    M: "1 month",
    MM: "%d months",
    y: "1 y",
    yy: "%d years"
  }
});
