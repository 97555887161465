import React from "react";
import Err from "../error";
import FormField from "../formElements/formField";

export default function CommonSignupQuestions({
  showErrors,
  error,
  errorPosition,
  errorMessage,
  username,
  password,
  password2,
  email,
  name,
  phone,
  passwordMatch
}) {
  return (
    <>
      <FormField
        id="username"
        value={username}
        question="username"
        required={true}
        showErrors={showErrors}
        error={error}
        errorPosition={errorPosition}
        errorMessage={errorMessage}
      />
      <FormField
        id="name"
        value={name}
        question="Full name"
        required={true}
        showErrors={showErrors}
        error={error}
        errorPosition={errorPosition}
        errorMessage={errorMessage}
      />
      <FormField
        id="email"
        value={email}
        question="Email address"
        type="email"
        required={true}
        showErrors={showErrors}
        error={error}
        errorPosition={errorPosition}
        errorMessage={errorMessage}
      />
      <FormField
        id="password"
        value={password}
        question="Password"
        type="password"
        required={true}
        showErrors={showErrors}
        error={error}
        errorPosition={errorPosition}
        errorMessage={errorMessage}
      />

      <div className="form-group">
        <label className="p-form-label" htmlFor="password2">
          confirm Password
        </label>
        <input
          type="password"
          className={`form-control ${!password2 &&
            showErrors &&
            "form-control-error"} ${passwordMatch && "form-control-success"}`}
          id="password2"
          value={password2}
          onChange={function() {
            /* no-op, change handled by the parent component (Form) */
          }}
          placeholder="Confirm password"
        />
      </div>

      <FormField
        id="phone"
        value={phone}
        question="Phone number"
        required={true}
        showErrors={showErrors}
        error={error}
        errorPosition={errorPosition}
        errorMessage={errorMessage}
      />
    </>
  );
}
