import React, {Component} from "react";
import "../contactus/contactus.scss";
import axios from "../../utils/axios";
import configs from "../../configs/envVariables";
const ApiBaseURI = configs.REACT_APP_API_DOMAIN;

export default class Redirect extends Component {

    state = {
        loaded: false,
        destinationFound: false
    };

    async componentDidMount() {
        const { user_slug, url_slug } = this.props.match.params;
        const redirect = await axios.get(`${ApiBaseURI}/redirects/find/${user_slug}/${url_slug}`);
        const destination = (redirect.data && redirect.data.data && redirect.data.data.destination) ? redirect.data.data.destination : false;

        if(destination){
            this.setState({
                loaded: true,
                destinationFound: true
            });
            window.location = destination;
        }else{
            this.setState({
                loaded: true,
                destinationFound: false
            });
        }
    }

    render(){
        const { loaded, destinationFound } = this.state;
        console.log(this.state);
        return (
            <div id="contactus" className="container">
                <div className="row py-5 px-2">
                    <h2 className="col-12 lead pb-4">Redirecting</h2>
                    <div className="contactus-info-container col-md-6 mx-auto px-md-5 py-md-5 py-4 px-4 text-left text-white shadow">
                        {(!loaded || destinationFound) && <p className="lead pb-4">Please wait while we redirect you to your destination.</p>}
                        {(loaded && !destinationFound) && <p className="lead pb-4">This link is invalid, we couldn't redirect you.</p>}
                    </div>
                </div>
            </div>
        );
    }
}
