import React, { Component } from "react";
import ChooseType from "./chooseType.view";
import { withRouter } from "react-router-dom";

class AccountType extends Component {
  state = {
    accountType: ""
  };

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.accountType) {
      this.setState({
        ...this.props.location.state,
        accountType: this.props.location.state.accountType
      });
    }
  }

  handleTypeClick = e => {
    const value = e.currentTarget.id;

    this.setState({ accountType: value.split("-")[1] });
  };

  render() {
    const { accountType } = this.state;
    return (
      <div>
        {accountType ? (
          // React.cloneElement(this.props.children(), { type: this.state.type })
          this.props.children({ ...this.state, ...this.props })
        ) : (
          <ChooseType handleTypeClick={this.handleTypeClick} />
        )}
      </div>
    );
  }
}

export default withRouter(AccountType);
