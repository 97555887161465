import React, { Component } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import axios from "axios";
import { withRouter } from "react-router-dom";

import "./customOffer.scss";
import Error from "../error";
import configs from "../../../configs/envVariables.js";

const ApiBaseURI = configs.REACT_APP_API_DOMAIN;

class CustomOffer extends Component {
  constructor(props) {
    super(props);
    this.formElement = React.createRef();
  }
  state = {
    email: "",
    phone: "",
    channelId: this.props.channelId,
    fullName: "",
    success: null,
    error: null,
    loading: false,
    channelType: this.props.accountType
  };

  componentDidMount() {
    this.formElement.current.scrollIntoView({
      behavior: "smooth",
      block: "center"
    });
  }

  handleFormChange = e => {
    const state = {};
    const fieldName = e.target.id;

    if (fieldName === "inputEmail1") {
      state.email = e.target.value;
    } else if (fieldName === "inputPhone") {
      state.phone = e.target.value;
    } else if (fieldName === "inputFullName") {
      state.fullName = e.target.value;
    }

    this.setState(state);
  };

  handleFormSubmit = e => {
    e.preventDefault();
    const invalidForm = this.invalidForm();

    if (invalidForm) {
      // set it to false first to allow re-animation
      return this.setState({ error: false }, () => {
        this.setState({
          error: invalidForm,
          success: false
        });
      });
    }

    this.setState({ loading: true, error: false }, async () => {
      try {
        await axios.post(`${ApiBaseURI}/lead`, {
          name: this.state.fullName,
          email: this.state.email,
          phone: this.state.phone,
          channelId: this.state.channelId
        });
        console.log("lead created", this.state.channelType);

        this.props.history.push("/signup", {
          email: this.state.email,
          name: this.state.fullName,
          phone: this.state.phone,
          channelId: this.state.channelId,
          accountType: "Influencer",
          channelType: this.state.channelType
        });
        this.setState({
          success: true,
          email: "",
          phone: "",
          fullName: "",
          loading: false
        });
      } catch (error) {
        this.setState({ loading: false, error: error.message });
      }
    });
  };

  invalidForm = () => {
    const { fullName, email, phone } = this.state;
    let message = "";
    if (!fullName) message += "full name field can't be empty";
    if (!email) message += `${message && ".\n"} email field can't be empty`;
    if (!phone) message += `${message && ".\n"} phone field can't be empty`;

    return message ? message : false;
  };
  render() {
    const { success, email, phone, loading, fullName, error } = this.state;

    return (
      <div id="custom-offer" ref={this.formElement} className="row mx-1 mt-3">
        <form
          onChange={this.handleFormChange}
          onSubmit={this.handleFormSubmit}
          className="col-lg-8 mx-auto text-left p-lg-4 py-4 px-4 px-lg-5 custom-offer-form rounded shadow mb-3 mt-0"
        >
          {loading && (
            <div className="linear-progress">
              <LinearProgress />
            </div>
          )}
          <div className="form-group">
            <label htmlFor="inputFullName">Full name</label>
            <input
              type="text"
              className="form-control"
              id="inputFullName"
              placeholder="Enter full name"
              value={fullName}
            />
          </div>
          <div className="form-group">
            <label htmlFor="inputEmail1">Email address</label>
            <input
              type="email"
              className="form-control"
              id="inputEmail1"
              placeholder="Enter email"
              value={email}
            />
            <small id="emailHelp" className="form-text text-muted">
              We'll use your email to send you custom offers.
            </small>
          </div>
          <div className="form-group">
            <label htmlFor="inputPhone">Phone Number</label>
            <input
              type="text"
              className="form-control"
              id="inputPhone"
              placeholder="phone number"
              value={phone}
            />
          </div>
          {/* {success && (
            <div className="col-lg-12 mx-auto mt-3">
              <Success message="Submitted successfully! you will hear from us very soon." />
            </div>
          )} */}
          {error && (
            <div className="col-lg-12 px-0 mx-auto mt-3">
              <Error message={error} />
            </div>
          )}
          <div className="d-flex justify-content-center pt-3">
            <button type="submit" className="btn p-btn-gradient p-2 px-5">
              Submit
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default withRouter(CustomOffer);
