import React, { Component } from "react";

import InfluencerDashboard from "./influencer";
import AdminDashboard from "./admin";
import BrandDashboard from "./brand";

import MainDashNavbar from "../common/dashboard/dashboardNavbars/mainNavbar";
import InfluencerLeftNavbar from "./influencer/leftNav";
import AdminLeftNavbar from "./admin/leftNav";
import BrandLeftNavbar from "./brand/leftNav";

import "./dashboards.scss";

/**
 * This component is responsible for the main layout structure.
 * It handles navigating between navbar components and showing/hiding.
 * left side navbar on small devices.
 *
 * Each navbar element must have a data-section attribute with the name of
 * the component to diplay as a value. e.g. data-section="dash-team" navbar
 * item must also have an onclick callback handler which is passed from this
 * component.
 *
 * when nav item is clicked the handleSectionClick method will
 * get the data-section value and pass the name of the component
 * to the corresponding dashboard Component (e.g InfluencerDashboard) which
 * in return will desplay the correct compoenent.
 */
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.navbarRef = React.createRef();
    this.state = {
      section: undefined, // initiated undefined to allow for default value on destructuring
      bodyTopPadding: 0,
      showLeftNav: false
    };
  }
  componentDidMount() {
    this.setBodyTopPadding();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.section !== this.state.section) {
      this.setBodyTopPadding();
    }
  }

  handleSectionClick = e => {
    e.preventDefault();
    this.setState({
      showLeftNav: false,
      section: e.currentTarget.dataset.section
    });
  };

  /**
   * used to dynamically set top padding of dash body
   * to account for navbar height because it iss positioned
   * absolute.
   * implemented this way rather than scss to decouple navbar
   * height from dash body padding
   */
  setBodyTopPadding = () => {
    const navBarHeight = this.navbarRef.current.clientHeight;
    const windowWidth = window.innerWidth;

    this.setState({
      bodyTopPadding: navBarHeight,
      showLeftNav: windowWidth > 450 ? true : false
    });
  };

  toggleLeftNav = () => {
    this.setState({ showLeftNav: !this.state.showLeftNav });
  };

  render() {
    const { vendor } = this.props.match.params;
    const { bodyTopPadding, section, showLeftNav } = this.state;

    return (
      <div id="dashboard" style={{ paddingTop: bodyTopPadding }}>
        <MainDashNavbar
          navbarRef={this.navbarRef}
          toggleLeftNav={this.toggleLeftNav}
          handleSectionClick={this.handleSectionClick}
        />
        <div
          className="container-fluid"
          style={{ minHeight: `calc(100vh - ${bodyTopPadding}px)` }}
        >
          <div className="row d-flex flex-nowrap">
            <div
              className={`dash-leftNav-container bg-white ${
                showLeftNav ? "" : "hide"
              }`}
            >
              <div ref={this.leftNavRef} className={`dash-leftNav px-4 pt-4`}>
                {vendor === "influencer" ? (
                  <InfluencerLeftNavbar
                    handleSectionClick={this.handleSectionClick}
                    currentSelected={section}
                  />
                ) : vendor === "admin" ? (
                  <AdminLeftNavbar
                    handleSectionClick={this.handleSectionClick}
                    currentSelected={section}
                  />
                ) : (
                  <BrandLeftNavbar
                    handleSectionClick={this.handleSectionClick}
                    currentSelected={section}
                  />
                )}
              </div>
            </div>
            <div className="dash-main p-3 pt-5 p-md-5">
              {vendor === "influencer" ? (
                <InfluencerDashboard section={section} />
              ) : vendor === "admin" ? (
                <AdminDashboard section={section} />
              ) : (
                <BrandDashboard section={section} />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
