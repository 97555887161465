import React, { Component } from 'react';
import { Formik, Form, Field, ErrorMessage } from "formik";
import {logout, refreshToken} from "../../../../redux/actions/auth.actions";
import {connect} from "react-redux";
import SectionTitle from "../../../common/dashboard/elements/sectionTitle";
import axios from "../../../../utils/axios";

import './redirects.scss'
import configs from "../../../../configs/envVariables";

const ApiBaseURI = configs.REACT_APP_API_DOMAIN;


class Redirects extends Component{

    state = {
        showAddRedirect: false,
        users: [],
        redirects: [],
        isSubmitting: false
    };

    async componentDidMount() {
        await this.fetchUsers();
        await this.fetchRedirects();
    }

    fetchUsers = async () => {
        const users = await axios.get(`${ApiBaseURI}/user`);
        console.log(users, 'users');
        this.setState({
            users: users.data
        });
    };

    fetchRedirects = async () => {
        const redirects = await axios.get(`${ApiBaseURI}/redirects`);
        this.setState({
            redirects: redirects.data
        });
    };

    handleAddRedirectClick = e => {
        e.preventDefault();
        this.setState({ showAddRedirect: !this.state.showAddRedirect });
    };

    render(){
        const {showAddRedirect, users, redirects, isSubmitting} = this.state;

        return (
            <div id="dash-redirects" className="container-fluid">
                <div className="row pb-4">
                    <div className="col-9">
                        <SectionTitle data="Redirects" />
                    </div>
                    <div className="col-3 text-right">
                        <a
                            href=""
                            onClick={this.handleAddRedirectClick}
                            className="redirect-add-btn shadow"
                        >
                            Add Redirect
                        </a>
                    </div>
                </div>
                <div className={`col-12 redirect-add-wrapper text-left ${!showAddRedirect && "hide"}`}>
                    <div className={`redirect-add-container p-3 p-md-5 bg-white shadow-sm my-4`}>
                        <Formik onSubmit={async (values, { setSubmitting, resetForm, setFieldError }) => {
                            this.setState({isSubmitting: true});
                            const user = this.state.users[values.user];
                            await axios.post(`${ApiBaseURI}/redirects/${user.ID}`, {
                                user_slug: user.Name,
                                url_slug: values.url_slug,
                                destination: values.Destination
                            });
                            this.setState({isSubmitting: false, showAddRedirect: false});
                            this.fetchRedirects();
                        }} initialValues={{
                            user: '',
                            url_slug: '',
                            destination: ''
                        }}>
                            <Form>
                                <div className="form-row">
                                    <div className="form-group col-md-4">
                                        <label htmlFor="user">User</label>
                                        <Field id='user' component="select" name="user" className="form-control">
                                            <option key={0} value='' />
                                            {users.map((user, index) => <option value={index} key={index}>{user.Name}</option>)}
                                        </Field>
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label htmlFor="url_slug">URL Slug</label>
                                        <Field id='url_slug' type="text" name="url_slug" className="form-control"/>
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label htmlFor="destination">Destination</label>
                                        <Field id='destination' type="text" name="destination" className="form-control"/>
                                    </div>
                                </div>
                                <button
                                    type="submit"
                                    className="btn btn-primary mr-2 shadow-sm"
                                    disabled={isSubmitting}
                                >Submit</button>
                            </Form>
                        </Formik>
                    </div>
                </div>
                <div className="row bg-white px-md-3 py-md-2 rounded">
                    <div className="col-12 py-3 d-flex justify-content-between align-items-center">
                        <table style={{ width: '100%' }}>
                            <thead>
                                <tr>
                                    <th>Link</th>
                                    <th>Destination</th>
                                </tr>
                            </thead>
                            <tbody>
                                {redirects.map((redirect, index) => <tr key={index}>
                                    <td>https://ziigg.com/z/{redirect.UserSlug}/{redirect.UrlSlug}</td>
                                    <td>{redirect.Destination}</td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }

}

const mapDispatch = {
    refreshToken,
    logout
};
export default connect(
    null,
    mapDispatch
)(Redirects);