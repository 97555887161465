import React, { Component } from "react";
// import Spinner from "@bit/bondz.react-epic-spinners.semipolar-spinner";

import LoginView from "./login.view";
// import Signup from "./signup.view";
// import SignupSuccess from "./signupSuccess.view";
import axios from "axios";
import { connect } from "react-redux";
import { validate } from "../../utils/login.utils";
import { login, logout } from "../../redux/actions/auth.actions";

// import { spinner } from "../../styles/main/searchbox.module.scss";
class Login extends Component {
  state = {
    username: "",
    password: "",
    validationError: false,
    errorPosition: null,
    errorMessage: null,
    showErrors: false
  };

  componentDidMount() {}

  handleSubmit = e => {
    console.log("handleSubmit");
    const { validationError, password, username } = this.state;
    e.preventDefault();

    if (!username && !password) {
      console.log("handleSubmit no username and no password");
      return this.setState({ showErrors: true });
    }
    if (validationError) {
      console.log("handleSubmit validationError, ", validationError);
      return;
    }
    console.log("handleSubmit logging in, ");
    this.props.login({
      username: username,
      password: password,
      history: this.props.history
    });
  };

  handleInputChange = e => {
    let obj = {};
    this._setAndValidateInputs(obj, e);
    this.setState(obj);
  };

  _setAndValidateInputs(obj, e) {
    let id = e.target.id;
    let value = e.target.value;
    let validation = {};
    if (id === "username") {
      obj.username = value;
    } else if (id === "password") {
      validation = validate("password", value);
      obj.password = value;
    }

    if (typeof validation.errorMessage === "string") {
      obj.validationError = true;
      obj.errorMessage = validation.errorMessage;
      obj.errorPosition = validation.position;
    } else {
      obj.validationError = false;
      obj.errorMessage = "";
      obj.errorPosition = null;
    }

    return obj;
  }

  render() {
    const { loginError, loginLoading, loginSuccess } = this.props;
    const { usename, password, showErrors } = this.state;

    return (
      <LoginView
        username={usename}
        password={password}
        loginError={loginError}
        loginLoading={loginLoading}
        handleSubmit={this.handleSubmit}
        handleInputChange={this.handleInputChange}
        showErrors={showErrors}
      >
        login
      </LoginView>
    );
  }
}

let mapDispatchToProps = {
  login,
  logout
};

const mapStateToProps = state => {
  return {
    loginLoading: state.auth.loginLoading,
    loginSuccess: state.auth.loginSuccess,
    loginError: state.auth.loginError
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
