import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getChannelId } from "../../../redux/actions/influencer.actions";

import "./influencerDashboard.scss";
import Soon from "../../common/soon";
import Offers from "./offers";
import Reports from "./reports";
import Account from "./account";
import Inbox from "../../common/dashboard/inbox";
import Notifications from "../../common/dashboard/notifications";

import withLogin from "../../common/HOC/withLogin";

class InfluencerDashboard extends Component {
  state = {
    justSignedup: false
  };

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.justSignedup) {
      this.setState({ justSignedup: true });
    }

    this.props.getChannelId();
  }

  componentDidUpdate(prevProp, prevState) {
    if (prevProp.section !== this.props.section) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { section = "dash-offers", primaryAccount } = this.props;

    if (section === "dash-offers")
      return <Offers {...this.state} primaryAccount={primaryAccount} />;

    if (section === "dash-reports") return <Reports {...this.state} />;

    if (section === "dash-stats")
      return (
        <div className="mx-auto">
          <Soon data="Stats section is" />
        </div>
      );
    if (section === "dash-banking")
      return (
        <div className="mx-auto">
          <Soon data="Banking section is" />
        </div>
      );
    if (section === "dash-account") return <Account />;
    if (section === "dash-inbox") return <Inbox />;
    if (section === "dash-notifications") return <Notifications />;
  }
}

const mapState = state => ({
  primaryAccount: state.influencer.primaryAccount
});
const mapDispatch = {
  getChannelId
};
export default connect(
  mapState,
  mapDispatch
)(withRouter(withLogin("Influencer")(InfluencerDashboard)));
