import React from 'react';
import FormField from "../common/formElements/formField";

import "./reset-password.scss";

const ResetView = ({ 
    onPasswordChange, 
    onConfirmPasswordChange, 
    onSubmit, 
    token, 
    successMessage, 
    errorMessage 
}) => {
    return (
        <div id="reset">
            <h2>Reset Password</h2>
            {successMessage && <div className="alert alert-success">{successMessage}</div>}
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            <form 
                className="reset-form mx-auto my-md-5 pt-4 text-center"
                onSubmit={onSubmit}>
                <input
                    className={`form-control`}
                    type="password"
                    id="password"
                    name="password"
                    required={true}
                    placeholder="New Password"
                    onChange={onPasswordChange}
                />
                <input
                    className={`form-control`}
                    id="confirmPassword"
                    type="password"
                    name="confirmPassword"
                    required={true}
                    placeholder="New Password Confirmation"
                    onChange={onConfirmPasswordChange}
                />
                {token && <input type="hidden" value={token} />}
                <button type="submit" className="btn btn-primary w-100 mt-4">Reset Password</button>
            </form>
        </div>
    );
};

export default ResetView;
