import React, { Component } from "react";
import { connect } from "react-redux";

import "./account.scss";
import SectionTitle from "../../../common/dashboard/elements/sectionTitle";

class Account extends Component {
  render() {
    const {
      username,
      email,
      phone,
      accountType,
      name,
      primaryAccount
    } = this.props;
    return (
      <div id="dash-account" className="container-fluid">
        <div className="row pb-4">
          <div className="col-12">
            <SectionTitle data="Account" />
          </div>
        </div>
        <div className=" row bg-white rounded">
          <div className="col-12 pt-5 pb-3 pl-md-5 pl-3">
            <h2 className="account-name">{name.toUpperCase()}</h2>
          </div>
          <div className="col-12 d-flex flex-column py-5 pl-md-5 pl-3">
            <p className="account-property d-flex flex-wrap ">
              <span className="account-key ">Account Type</span>
              <span className="account-value text-uppercase">
                {accountType}
              </span>
            </p>
            <p className="account-property d-flex flex-wrap pb-5">
              <span className="account-key ">Channel Name</span>
              <span className="account-value text-uppercase">
                {primaryAccount.accountName}
              </span>
            </p>
            <p className="account-property d-flex flex-wrap ">
              <span className="account-key ">Channel ID</span>
              <span className="account-value text-uppercase">
                {primaryAccount.accountId}
              </span>
            </p>
            <p className="account-property d-flex flex-wrap ">
              <span className="account-key">Name</span>
              <span className="account-value">{name}</span>
            </p>
            <p className="account-property d-flex flex-wrap ">
              <span className="account-key">Username</span>
              <span className="account-value">{username}</span>
            </p>
            <p className="account-property d-flex flex-wrap ">
              <span className="account-key">Email</span>
              <span className="account-value">{email}</span>
            </p>
            <p className="account-property d-flex flex-wrap ">
              <span className="account-key">Phone</span>
              <span className="account-value">{phone}</span>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const mapState = state => ({
  username: state.auth.username,
  userId: state.auth.userId,
  email: state.auth.email,
  phone: state.auth.phone,
  name: state.auth.name,
  accountType: state.auth.accountType,
  primaryAccount: state.influencer.primaryAccount
});

export default connect(mapState)(Account);
