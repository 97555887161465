import axios from "../../utils/axios";
import {
  RESERVE_LOADING,
  RESERVE_SUCCESS,
  RESERVE_ERROR,
  ADD_ACCOUNTS
} from "../types";
import configs from "../../configs/envVariables.js";

const ApiBaseURI = configs.REACT_APP_API_DOMAIN;

export const reserveOffer = (offer, userId) => async dispatch => {
  dispatch({ type: RESERVE_LOADING });
  try {
    await axios.post(`${ApiBaseURI}/user/${userId}/reserve`, {
      reserve: offer
    });
    dispatch({ type: RESERVE_SUCCESS, payload: { offer } });
  } catch (err) {
    dispatch({ type: RESERVE_ERROR, payload: { message: err.message } });
  }
};

export const getChannelId = () => async (dispatch, getState) => {
  const userId = getState().auth.userId;
  try {
    const response = await axios.get(`${ApiBaseURI}/influencer/${userId}`);

    dispatch({ type: ADD_ACCOUNTS, payload: response.data.data.accounts });
  } catch (err) {
    console.error(err);
  }
};
