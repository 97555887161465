import React, { Component } from "react";
import Err from "../error";
import Proptypes from "prop-types";

import "./recursiveQuestions.scss";

/**
 * takes an array of questions
 *
 * each question may contain an array of questions itself
 * and each of those child questions must have a condition
 * property which will be compared against the parents answer
 * to determin whether to display it.
 *
 * If question only accepts predefined answers you must provide
 * and "options" property that is of type array and accepts
 * a list of answers.
 */
export default class RecursiveQuestions extends Component {
  state = {
    questions: []
  };
  static getDerivedStateFromProps(props, state) {
    if (!state.questions.length) {
      return {
        questions: props.questions
      };
    }
    return {};
  }

  componentDidMount() {
    const { handleQuestionsChange } = this.props;
    let questions = JSON.parse(JSON.stringify(this.state.questions));
    handleQuestionsChange(this.checkForErrors(questions), questions);

    this.setState({ questions });
  }

  handleInputChange = e => {
    e.stopPropagation();
    let { questions } = this.state;
    let { handleQuestionsChange } = this.props;
    let value = e.target.value;
    questions = JSON.parse(JSON.stringify(questions));

    if (e.target.dataset.questionid) {
      let questionId = parseInt(e.target.dataset.questionid);
      let question = this.findQuestionById(questionId, questions);
      if (question.id === questionId) {
        question.answer = value;
      }

      handleQuestionsChange(this.checkForErrors(questions), questions);

      this.setState({ questions });
    }
  };

  /**
   * checks for errors and modifies error property on
   * each question before return a bool
   */
  checkForErrors = questions => {
    let hasErrors = false;
    let findErrorsRecursively = (questions, parentAnswer) => {
      if (!questions) return false;

      questions.forEach(question => {
        if (
          this.hasValidationError(question, question.answer) &&
          this.meetsCondition(question, parentAnswer)
        ) {
          question.error = this.hasValidationError(question, question.answer);
          hasErrors = true;
        } else {
          question.error = false;
          findErrorsRecursively(question.questions, question.answer);
        }
      });
    };
    findErrorsRecursively(questions);

    return hasErrors;
  };

  getQuestionsElements = (questions, parentAnswer) => {
    return questions.map(question => {
      if (!this.meetsCondition(question, parentAnswer)) return null;

      if (!question.options) {
        return this.getTextQuestionElement(question);
      } else if (question.options) {
        return this.getOptionsQuestionElement(question);
      }
    });
  };

  /**
   * used to get questions that accept predefined options
   * @param {Object} question
   */
  getOptionsQuestionElement(question) {
    const { showErrors } = this.props;

    if (question.options.length < 3) {
      return (
        <div
          key={question.id}
          className={`form-group mb-5 ${
            question.parentQuestionId ? "animate-expand child-question" : "mx-2"
          }`}
        >
          {showErrors && question.error && (
            <Err message={question.error} style={{ marginBottom: 0 }} />
          )}
          <div className="pb-2 text-secondary">{question.question}?</div>
          <div className="d-flex">
            {question.options.map(option => {
              return (
                <div key={option} className="form-check form-check-inline">
                  <input
                    data-questionid={question.id}
                    type="radio"
                    className="form-check-input"
                    id={`form-input-id-${option}`}
                    value={option}
                    name={question.id}
                    onChange={this.handleInputChange}
                  />
                  <label
                    className="form-check-label text-secondary"
                    htmlFor={question.id}
                  >
                    {option}
                  </label>
                </div>
              );
            })}
          </div>
          {question.questions &&
            this.getQuestionsElements(question.questions, question.answer)}
        </div>
      );
    } else {
      return (
        <div
          className={`form-group ${
            question.parentQuestionId ? "animate-expand child-question" : "mx-2"
          }`}
        >
          <p className="pb-2 text-secondary mb-1">{question.question}?</p>
          {showErrors && question.error && (
            <Err message={question.error} style={{ marginBottom: 0 }} />
          )}
          <select
            className="form-control"
            data-questionid={question.id}
            onChange={this.handleInputChange}
            value={question.answer}
          >
            {question.options &&
              question.options.map(option => {
                return <option key={option}>{option}</option>;
              })}
          </select>
          {question.questions &&
            this.getQuestionsElements(question.questions, question.answer)}
        </div>
      );
    }
  }

  getTextQuestionElement = question => {
    const { showErrors } = this.props;
    return (
      <div
        key={question.id}
        className={`${
          question.parentQuestionId ? "animate-expand child-question" : "mx-2"
        }`}
      >
        <div className="form-group">
          <label className="p-form-label" htmlFor={question.id}>
            {question.question}
          </label>
          <input
            type="text"
            data-questionid={question.id}
            className={`form-control ${question.error &&
              showErrors &&
              "form-control-error"}`}
            id={question.id}
            placeholder={question.question}
            onChange={this.handleInputChange}
          />
        </div>
        {question.questions &&
          this.getQuestionsElements(question.questions, question.answer)}
      </div>
    );
  };

  findQuestionById(questionId, questions) {
    let result = null;

    let findQuestionRecursively = (questionId, questions) => {
      if (!questions) return false;

      questions.forEach(question => {
        if (result) return;
        if (question.id === questionId) {
          result = question;
        } else {
          findQuestionRecursively(questionId, question.questions);
        }
      });
    };
    findQuestionRecursively(questionId, questions);

    return result;
  }

  isCheckBoxQuestion(question) {
    if (question.options && question.options.length < 3) return true;

    return false;
  }

  hasValidationError(question, value) {
    if (question.required) {
      if (value === "") return "Field below is required";
    }

    return false;
  }

  meetsCondition(question, parentAnswer) {
    if (!question.condition) return true;
    if (!parentAnswer) return false;

    if (
      parentAnswer === question.condition ||
      (typeof question.condition === "boolean" &&
        Boolean(parentAnswer) === question.condition)
    )
      return true;
  }
  render() {
    const { questions } = this.state;
    return (
      <div id="recursiveQuestions">
        {questions && this.getQuestionsElements(questions)}
      </div>
    );
  }
}

RecursiveQuestions.propTypes = {
  showErrors: Proptypes.bool.isRequired,
  handleQuestionsChange: Proptypes.func,
  questions: Proptypes.arrayOf(
    Proptypes.shape({
      id: Proptypes.any,
      question: Proptypes.string.isRequired,
      answer: Proptypes.string,
      required: Proptypes.bool,
      options: Proptypes.array,
      parentQuestionId: Proptypes.any,
      questions: Proptypes.array
    })
  )
};
