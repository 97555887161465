import React from "react";
import "./potentialOffers.scss";

export default function PotentialOffers({ handleCustomOfferBtnClick }) {
  return (
    <div id="potential-offers" className="row">
      <div className="col-12">
        <h4 className="mb-lg-5 p-3 mx-auto offers-title border">
          POTENTIAL BRANDS
        </h4>
      </div>
      <div className="row offers pb-4 w-100">
        {offers.slice(0, 4).map(offer => {
          return (
            <div key={offer.logo} className="pot-brand col-6 col-md-3 py-3">
              <div className="offer-logo mx-auto py-3">
                <img className="img-fluid" src={offer.logo} alt={offer.name} />
              </div>
            </div>
          );
        })}
        <div className="unlock-offers">
          <i className="fas fa-unlock-alt fa-4x mx-auto my-2" />
          <p>To see potential brands you need to signup below</p>
        </div>
      </div>
      <div className="col-12 py-5 mt-3">
        <div className="lead getCustomOffer-header px-4 py-3 mb-3 rounded mx-auto">
          <p className="mb-2">
            <span className="font-weight-bold">Congratulations!</span> You
            qualify for an “Instant Offer”!
          </p>
          <p className="getCustomOffer-subHeader mx-auto">
            You may qualify for “Private Offers “, “Ad-On Offers” and ongoing
            revenue opportunities!
          </p>
        </div>
        <a
          href=""
          onClick={handleCustomOfferBtnClick}
          className="btn text-white p-btn-gradient py-3 px-5 font-weight-bold"
        >
          GET STARTED
        </a>
      </div>
    </div>
  );
}

const offers = [
  {
    item: 1,
    name: "luminesce",
    logo: "/imgs/opportunities/luminesce-logo.png",
    picture: "/imgs/opportunities/luminesce-skin-care.png",
    discription:
      "Dermatologist-developed, the Luminesce® skin care line reduces the appearance of fine lines and wrinkles and restores youthful vitality and radiance to your skin. "
  },
  {
    item: 2,
    name: "luminesce",
    logo: "/imgs/opportunities/instantly-ageless-logo.png",
    picture: "/imgs/opportunities/instantly-ageless-box-vial-500.png",
    discription:
      "Instantly Ageless immediately tightens and smooths the skin’s appearance, effectively reducing the look of under-eye bags, fine lines, wrinkles and enlarged pores."
  },
  {
    item: 3,
    name: "luminesce",
    logo: "/imgs/opportunities/nv-logo-300x172.png",
    picture:
      "/imgs/opportunities/nv-skin-primer-foundation-bronzer-600-231x300.png",
    logoStyle: { maxWidth: "50%" },
    discription:
      "Formulated with exclusive, youth-enhancing APT-200™, NV™ includes a skin-perfecting primer, foundation and bronzer that give you an enviable, professional airbrush finish."
  },
  {
    item: 4,
    name: "luminesce",
    logo: "/imgs/opportunities/Volugio-Logo-White.png",
    logoStyle: { maxWidth: "50%" },
    picture: "/imgs/opportunities/volugio-prescription-sunglasses-300x203.jpg",
    discription:
      "Volugio  is a boutique eyewear company specializing in prescription sports sunglasses and other eyewear for active people. Their Free-Form Digital prescription lenses are the best Rx lenses for sports on the planet."
  },
  {
    item: 5,
    name: "luminesce",
    logo: "/imgs/opportunities/1800MEDIGAP.png",
    logoStyle: { maxWidth: "50%" },
    picture: "/imgs/opportunities/medigap-man.jpeg",
    imageStyle: { transform: "translateY(-25px)" },
    discription:
      "Retirement can be confusing. Our professionals are trained, certified and licensed by your State Department of Insurance to assist our customers by providing qualified help when and how they need it."
  },
  {
    item: 6,
    name: "luminesce",
    logo: "/imgs/opportunities/zen-logo-300x133.png",
    logoStyle: { maxWidth: "50%" },
    picture: "/imgs/opportunities/Zen-weight-management-supplement-300x213.png",
    discription:
      "The ZEN product line is scientifically formulated with premium ingredients found in nature that work synergistically to eliminate toxins, feed your body, and help you achieve your weight management goals."
  },
  {
    item: 7,
    name: "luminesce",
    logo: "/imgs/opportunities/RVL-logo.png",
    picture: "/imgs/opportunities/RVL-luxury-hair-care-300x300.jpg",
    discription:
      "RVL is a luxury at-home hair care system that includes botanicals like fenugreek, flaxseed oil and jojoba oil. Each product works to help gently revitalize the appearance of your hair and scalp for fuller looking hair."
  },
  {
    item: 8,
    name: "luminesce",
    logo: "/imgs/opportunities/naara-logo.png",
    picture: "/imgs/opportunities/naara-collagen-building-supplement.png",
    discription:
      "Skin care you can drink, Naära™ provides 5,000 mg TruMarine™ Collagen* to help replenish skin’s natural loss of collagen over time."
  },
  {
    item: 9,
    name: "luminesce",
    logo: "/imgs/opportunities/revitablu-logo-white-150x150.png",
    logoStyle: { maxWidth: "50%" },
    picture: "/imgs/opportunities/revitablu-stem-cell-supplement-300x300.png",
    discription:
      "Formulated by a pioneer in stem cell research, RevitaBlū is a botanical blend of blue-green algae, sea buckthorn berry and aloe vera with coconut water powder. It’s a refreshing drink mix that tastes as great as it works."
  }
];
