import React from 'react';
import FormField from "../common/formElements/formField";

import "./request-reset.scss";
const RequestResetView = ({ email, onEmailChange, onSubmit, error }) => {
    return (
        <div id="resetrequest">
            <h2>Request Password Reset</h2>
            <form className="reset-form mx-auto my-md-5 pt-4 text-center"
            onSubmit={onSubmit}>
                <input                    className={`form-control`}

                    type="email"
                    name="email"
                    value={email}
                    placeholder="Enter your email"
                    required={true}
                    onChange={onEmailChange}
                />
                <button type="submit" className="btn btn-primary w-100 mt-4" >Send Reset Link</button>
            </form>
            {error && <p style={{ color: 'red' }}>{error}</p>}
        </div>
    );
};

export default RequestResetView;
