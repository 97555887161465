import React, { Component } from "react";
import PropTypes from "prop-types";
import Err from "../../error";
import { CircularProgress } from "@material-ui/core";

import "./formField.scss";

export default class FormTextField extends Component {
  state = {
    showValidation: false
  };

  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    id: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
    required: PropTypes.bool
  };

  runValidation = async e => {
    const { validationFunction } = this.props;
    if (typeof validationFunction === "function") {
      this.setState({
        showValidation: true
      });
      validationFunction(e.target.value);
    }
  };

  render() {
    const {
      value,
      id,
      question,
      type,
      required,
      showErrors,
      error,
      errorPosition,
      errorMessage,
      valid
    } = this.props;
    const { showValidation } = this.state;

    return (
      <div id="formField" className="form-group">
        {showErrors && error && errorPosition === id && (
          <Err message={errorMessage} />
        )}
        <label className="p-form-label" htmlFor="channelId">
          {question}
        </label>
        {type !== "textaria" ? (
          <input
            type={type ? type : "text"}
            className={`form-control ${required &&
              !value &&
              showErrors &&
              "form-control-error"}`}
            id={id}
            value={value}
            onChange={e => {
              if (showValidation) {
                this.runValidation(e);
              }
            }}
            placeholder={question + `${required ? "*" : ""}`}
            onBlur={this.runValidation}
          />
        ) : (
          <textarea
            className={`form-control ${required &&
              !value &&
              showErrors &&
              "form-control-error"}`}
            id={id}
            value={value}
            onChange={e => {
              if (showValidation) {
                this.runValidation(e);
              }
            }}
            placeholder={question + `${required ? "*" : ""}`}
            onBlur={this.runValidation}
          />
        )}
        {showValidation && (
          <span className="field-validation">
            {valid === null ? (
              <CircularProgress size="25px" />
            ) : valid === true ? (
              <span style={{ color: "green" }}>
                <i className="fa fa-check-square fa-lg" aria-hidden="true" />
              </span>
            ) : (
              <span style={{ color: "red" }}>
                <i
                  className="fa fa-exclamation-circle fa-lg"
                  aria-hidden="true"
                />
              </span>
            )}
          </span>
        )}
      </div>
    );
  }
}
