/**
 * all environment variables should be imported from this module.
 * dotenv files in the root directory will take precedent in the
 * prod and dev environments. only override variables here when
 * necessary, otherwise only declare them in dotenv files. For staging
 * and other environments we override dotenv files below. The reason I (tarek)
 * took this approch is because dotenv package doesn't support a staging
 * environment and CRA doesn't allow NODE_ENV overriding.
 *
 * To use staging enviroment you need to set env variable REACT_APP_ENV to
 * "staging" when building image, else the environment used will
 * be based on NODE_ENV value which is set for you automatically by CRA
 */
const prod = {
  ...process.env
  // setting variable here will override dotenv file variables if names match
};

const dev = {
  ...process.env
  // setting variable here will override dotenv file variables if names match
};

const stage = {
  ...process.env,
  REACT_APP_API_DOMAIN: "https://stagingapi.ziigg.com/api/v1",
  REACT_APP_API_DOMAIN_v2: "https://stagingapi.ziigg.com/api/v2"
};

const NODE_ENV = process.env.NODE_ENV;
const REACT_APP_ENV = process.env.REACT_APP_ENV
  ? process.env.REACT_APP_ENV.trim()
  : "";

// dev environment is default to avoid touching prod
let config = dev;

if (NODE_ENV === "production") {
  config = REACT_APP_ENV === "staging" ? stage : prod;
}

export default config;
