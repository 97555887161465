import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import withLogin from "../../common/HOC/withLogin";

import "./adminDashboard.scss";
import Soon from "../../common/soon";
import Leads from "./leads";
import Inbox from "../../common/dashboard/inbox";
import Notifications from "../../common/dashboard/notifications";
import Team from "./team";
import Brands from "./brands";
import Redirects from "./redirects";

class InfluencerDashboard extends Component {
  state = {
    justSignedup: false
  };

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.justSignedup) {
      this.setState({ justSignedup: true });
    }
  }

  componentDidUpdate(prevProp, prevState) {
    if (prevProp.section !== this.props.section) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { section = "dash-leads" } = this.props;

    if (section === "dash-leads") return <Leads />;
    if (section === "dash-inbox") return <Inbox />;
    if (section === "dash-notifications") return <Notifications />;
    if (section === "dash-team") return <Team />;
    if (section === "dash-redirects") return <Redirects data="redirects section is" />;
    if (section === "dash-brands") return <Brands data="brands section is" />;

    if (section === "dash-stats") return <Soon data="Stats section is" />;
    if (section === "dash-banking") return <Soon data="Banking section is" />;
    if (section === "dash-reports") return <Soon data="report section is" />;
    if (section === "dash-account") return <Soon data="account section is" />;
  }
}

const mapState = state => ({
  primaryAccount: state.influencer.primaryAccount
});

export default connect(
  mapState,
  null
)(withRouter(withLogin("Admin")(InfluencerDashboard)));
