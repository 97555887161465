import React from "react";

import "./info.scss";

export default function Info() {
  return (
    <div id="info" className="container py-5 my-5 text-lg-left">
      <div className="row my-3 border-top border-bottom p-wrapper">
        <div className="d-flex flex-wrap p-4 py-5">
          <div className="col-lg-4 p-logo w-75 mx-auto">
            <img src="/imgs/logo.png" alt="ziigg logo" className="img-fluid" />
          </div>
          <div className="col-lg-8 align-self-center">
            <h3 className="display-4 font-weight-bold p-title">ZIIGG</h3>
            <p className="lead text-muted">
              connects positive, high-impact influencers with high-integrity
              brands that understand the value of a great influencer. We
              specialize in influencer marketing campaign strategy and
              excecution, performance marketing, paid social, and integrated
              brand strategy.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
