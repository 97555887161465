import React from "react";

import "./chooseType.scss";

export default function ChooseType({ handleTypeClick }) {
  return (
    <div id="chooseType" className="container my-5 py-5">
      <h3 className="text-muted chooseType-heading">Choose account type</h3>
      <div className="row d-flex justify-content-center py-5">
        <div
          id="type-Influencer"
          className="card col-md-5 mx-md-3 border-0 mb-3"
          onClick={handleTypeClick}
        >
          <div className="card-body text-white">Influencer</div>
        </div>
        <div
          id="type-Brand"
          className="card col-md-5 mx-md-3 border-0 mb-3"
          onClick={handleTypeClick}
        >
          <div className="card-body text-white">Brand</div>
        </div>
      </div>
    </div>
  );
}
