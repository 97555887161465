import React from "react";
import Slider from "react-slick";
import "./influencer.scss";

export default function Influencers() {
  let settings = {
    dots: true,
    slidesPerRow: 3,
    arrows: true,
    autoplay: true
  };

  return (
    <section id="influencers" className="py-5 my-5 ">
      <div className="container pb-5 pt-3 border-top border-bottom text-center">
        <div className="row pb-3">
          <div className="col text-center">
            <h3>Influencers</h3>
            <p className="lead text-muted">
              meet some successful influencers already engaged with one of our
              brands
            </p>
          </div>
        </div>
        <Slider {...settings}>
          <div className="image-container pb-2">
            <div className="image-wrapper px-lg-4">
              <a
                href="https://www.youtube.com/channel/UCWkDFq1pO7YNzifE3A4UsMA"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  src="/imgs/valeria-lipovetsky-3.png"
                  alt="valeria lipovetsky"
                />
              </a>
            </div>
          </div>
          <div className="image-container pb-2">
            <div className="image-wrapper px-lg-4">
              <a
                href="https://www.youtube.com/user/dope2111"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src="/imgs/promise-phan-3.png" alt="valeria lipovetsky" />
              </a>
            </div>
          </div>
          <div className="image-container pb-2">
            <div className="image-wrapper px-lg-4">
              <a
                href="https://www.youtube.com/user/MorgansBBD"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  src="/imgs/beauty-breakdown.png"
                  alt="valeria lipovetsky"
                />
              </a>
            </div>
          </div>
          <div className="image-container pb-2">
            <div className="image-wrapper px-lg-4">
              <a
                href="https://www.youtube.com/user/coolirpa"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src="/imgs/coolirpa-4.png" alt="valeria lipovetsky" />
              </a>
            </div>
          </div>
          <div className="image-container pb-2">
            <div className="image-wrapper px-lg-4">
              <a
                href="https://www.youtube.com/user/BusbeeStyleTV"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src="/imgs/erin-busbee-3.png" alt="valeria lipovetsky" />
              </a>
            </div>
          </div>
          <div className="image-container pb-2">
            <div className="image-wrapper px-lg-4">
              <a
                href="https://www.youtube.com/channel/UCWkDFq1pO7YNzifE3A4UsMA"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  src="/imgs/valeria-lipovetsky-3.png"
                  alt="valeria lipovetsky"
                />
              </a>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
}
