/**
 * TODOS:
 *
 * allow new brands to be marked orange for longer than
 * the refetch rate.
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import SectionTitle from "../../../common/dashboard/elements/sectionTitle";
import moment from "moment";

import { refreshToken, logout } from "../../../../redux/actions/auth.actions";
import "./brands.scss";
import axios from "../../../../utils/axios";
import {
  clone,
  findNewElements,
  withinLast24Hours,
  isMoreRecentDate
} from "../../../../utils/utils";
import { LinearProgress, CircularProgress } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import configs from "../../../../configs/envVariables.js";

const ApiBaseURI = configs.REACT_APP_API_DOMAIN;

// hardcoded temp until api is ready
const brandCriteria = {
  bonusType: { value: "sale_percentage", label: "Bonus sale percentage" },
  bonus_percentage: 43,
  bonus_upfront: 23,
  budget: 5000,
  categories: [{ value: "Music", label: "Music" }],
  comment: "",
  countries: [{ value: "United States", label: "United States" }],
  likeToDislike: 50,
  maxInfluencerPayout: 1000,
  minSubscribers: 8000,
  minViews: 5000,
  productsPerVideo: 1
};

const brandCriteriaLabels = {
  bonusType: "Bonus type",
  bonus_percentage: "Bonus %",
  bonus_upfront: "Upfront Bonus",
  budget: "Budget",
  categories: "Categories",
  comment: "Comment",
  countries: "Countries",
  likeToDislike: "Like to Dislike",
  maxInfluencerPayout: "Max. Influencer Payout",
  minSubscribers: "Min. Subscribers",
  minViews: "Min. views/video",
  productsPerVideo: "Max. products/video"
};

class Brands extends Component {
  state = {
    loading: true,
    originalbrand: [],
    brands: [],
    sortBy: "sort-by",
    clickedbrandId: null,
    deleteLoading: false,
    timeFromLastFetch: "now",
    expandItem: false,
    sorts: [
      ["Sort by", "sort-by"],
      ["Most recent", "most-recent"],
      ["Oldest first", "oldest"]
    ]
  };
  componentDidMount() {
    this.fetchBrands({ markNew: false });
  }

  componentWillUnmount() {
    clearInterval(this.fetchBrandsInterval);
    clearInterval(this.timeFromLastFetchInterval);
  }

  handleSortBy = e => {
    const sortby = e.target.dataset.sortby;
    let brands = [...this.state.brands];

    if (sortby === "most-recent") {
      brands = this.sortbrandsByDate(this.state.brands, true);
    } else if (sortby === "oldest") {
      brands = this.sortbrandsByDate(this.state.brands, false);
    }

    this.setState({ brands, sortBy: sortby });
  };

  handleDelete = e => {
    e.stopPropagation();

    if (!window.confirm("Are you sure you want to delete this brand?")) return;
    let brandId = e.currentTarget.dataset.brandid;
    this.setState({ deleteLoading: true }, async () => {
      try {
        await axios.delete(`${ApiBaseURI}/brand/${brandId}`);
      } catch (err) {
        // TODO handle error
        console.error(err);
        this.setState({ deleteLoading: false });
      }
      this.fetchBrands({ markNew: false });
    });
  };
  handlebrandClick = e => {
    let brandId = parseInt(e.currentTarget.dataset.brandid);

    this.setState({
      clickedbrandId: brandId,
      expandItem: !this.state.expandItem
    });
  };

  handleTimeFromClick = () => {
    this.setState(
      {
        timeFromLastFetch: "now"
      },
      () => {
        this.fetchBrands();
      }
    );
  };

  fetchBrands = ({ markNew } = { markNew: true }) => {
    axios
      .get(`${ApiBaseURI}/brand`)
      .then(res => {
        let brands = res.data;

        // on first data fetch we don't need new brands
        if (markNew) brands = this.markNewbrands(brands);
        brands = this.markTodaysbrands(brands);
        brands = this.sortbrands(brands);

        // todo: remove line below when criteria api is ready
        brands.forEach(brand => (brand.criteria = brandCriteria));

        this.setState({
          brands,
          originalbrand: res.data.data,
          loading: false,
          deleteLoading: false,
          lastFetched: moment()
        });
      })
      .catch(err => {
        if (err.status === 401) {
          this.props.logout();
        }
        console.error(err);
      });
  };

  /**
   * gits rid of duplicate brands & ensures only most
   * up to date version of each brand is kept
   */
  aggregatebrands = brands => {
    const resultSet = new Map();
    const result = [];

    brands.forEach(brand => {
      if (!resultSet.has(brand.search)) {
        result.push(brand);
        resultSet.set(brand.search, {
          create_time: brand.create_time,
          index: result.length - 1
        });
      } else if (
        isMoreRecentDate(
          brand.create_time,
          resultSet.get(brand.search).create_time
        )
      ) {
        let itemIndex = resultSet.get(brand.search).index;
        result.splice(itemIndex, 1, brand);
        resultSet.set(brand.search, {
          create_time: brand.create_time,
          index: itemIndex
        });
      }
    });

    return result;
  };

  sortbrands = brands => {
    const { sorts } = this.state;
    const { sortBy } = this.state;

    if (sortBy === sorts[0][1] || sortBy === sorts[1][1])
      brands = this.sortbrandsByDate(brands);

    if (sortBy === sorts[2][1]) brands = this.sortbrandsByDate(brands, false);

    return brands;
  };
  /**
   * @param {BOOLEAN} bool if true it sorts largest/closest first
   */
  sortbrandsByDate = (brands, bool = true) => {
    brands = [...brands];
    return brands.sort(function(a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      if (bool) {
        return moment(b.create_time).toDate() - moment(a.create_time).toDate();
      } else {
        return moment(a.create_time).toDate() - moment(b.create_time).toDate();
      }
    });
  };

  /**
   * adds "new" property to new brands
   * TODO: fix - when fetching intervally it is marking brands
   * as new when they are not.
   */
  markNewbrands = newbrands => {
    newbrands = clone(newbrands);
    const newElementsIndexes = findNewElements(
      newbrands,
      this.state.brands,
      "id"
    );
    newElementsIndexes.forEach(index => {
      newbrands[index].new = true;
    });

    return newbrands;
  };

  markTodaysbrands = newbrands => {
    newbrands.forEach(brand => {
      if (
        withinLast24Hours(
          moment(brand.create_time)
            .toDate()
            .getTime()
        )
      ) {
        brand.newToday = true;
      }
    });

    return newbrands;
  };

  render() {
    const {
      brands,
      sortBy,
      loading,
      clickedbrandId,
      deleteLoading,
      sorts,
      timeFromLastFetch,
      expandItem
    } = this.state;
    return (
      <div id="dash-brands" className="container-fluid">
        <div className="row pb-4">
          <div className="col-12">
            <SectionTitle data="Brands" />
          </div>
        </div>
        <div className="row bg-white px-md-3 py-md-2 rounded">
          <div className="col-12 py-3 d-flex justify-content-end align-items-center">
            <div className="dropdown show" onClick={this.handleSortBy}>
              <span
                className="btn dropdown-toggle"
                href="#"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {sorts.find(sort => sort[1] === sortBy)
                  ? sorts.find(sort => sort[1] === sortBy)[0]
                  : sorts[0][0]}
              </span>

              <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                {sorts.map((sort, i) => {
                  if (i) {
                    return (
                      <span
                        key={i}
                        className="dropdown-item"
                        data-sortby={sort[1]}
                        href="#"
                      >
                        {sort[0]}
                      </span>
                    );
                  }
                })}
              </div>
            </div>
          </div>

          <div className="col-12 rounded">
            <ul className="list-group pb-5">
              {brands.map((brand, index) => {
                return (
                  <li
                    key={brand.search}
                    data-brandid={brand.ID}
                    className={`list-group-item py-3 d-flex flex-wrap align-items-center ${brand.new &&
                      "new-brand"} ${brand.newToday && "new-brand-today"}`}
                    onClick={this.handlebrandClick}
                  >
                    <span className="list-item-key brand">
                      Brand:{" "}
                      <span className="list-item-value text-sm">
                        {brand.Name}
                      </span>
                    </span>
                    <span className="list-item-key brand ">
                      Added on:{" "}
                      <span className="list-item-value text-bold">
                        {moment(brand.create_time)
                          .toDate()
                          .toLocaleDateString()
                          .replace(/\//g, "-")}
                      </span>
                    </span>
                    <span className="list-item-key brand ">
                      Budget:{" "}
                      <span className="list-item-value text-bold">
                        ${brand.criteria.budget.toLocaleString() || "n/a"}
                      </span>
                    </span>
                    <span className="list-item-key brand ">
                      Bonus:{" "}
                      <span className="list-item-value text-bold">
                        %{brand.criteria.bonus_percentage || "n/a"}
                      </span>
                    </span>
                    <span
                      className={`list-item-key delete-icon d-flex justify-content-end pt-4 pt-md-1 ${clickedbrandId ===
                        brand.id && "clicked"}`}
                    >
                      {deleteLoading && clickedbrandId === brand.id ? (
                        <CircularProgress
                          style={{ color: "rgb(156, 185, 199)" }}
                        />
                      ) : (
                        <DeleteIcon
                          data-brandid={brand.id}
                          onClick={this.handleDelete}
                        />
                      )}
                    </span>
                    <div
                      className={`sub-li ${expandItem &&
                        clickedbrandId === brand.id &&
                        "expand"}`}
                      onClick={e => e.stopPropagation()}
                    >
                      <ul className="list-group d-flex flex-wrap flex-row pt-4">
                        <p className="sub-li__title lead">Criteria</p>
                        {Object.keys(brand.criteria).map(key => {
                          const value = brand.criteria[key];
                          if (typeof value !== "object")
                            return (
                              <li className="sub-li-item list-item-key brand ">
                                {brandCriteriaLabels[key]}:{" "}
                                <span className="list-item-value text-bold">
                                  {value || "n/a"}
                                </span>
                              </li>
                            );
                        })}
                      </ul>
                      <ul className="list-group d-flex flex-wrap flex-row pt-4">
                        <p className="sub-li__title lead">Brand Info</p>

                        {Object.keys(brand).map(key => {
                          const value = brand[key];
                          if (
                            ["newToday", "logo", "picture", "link"].includes(
                              key
                            )
                          )
                            return;
                          if (key === "description") {
                            return;
                          } else if (
                            ["created_at", "updated_at"].includes(key)
                          ) {
                            return (
                              <li className="sub-li-item list-item-key brand d-flex align-items-center">
                                {key}:{" "}
                                <span className="list-item-value text-bold">
                                  {moment(value).format("MMM Do YY") || "n/a"}
                                </span>
                              </li>
                            );
                          } else if (typeof value !== "object")
                            return (
                              <li className="sub-li-item list-item-key brand d-flex align-items-center">
                                {key}:{" "}
                                <span className="list-item-value text-bold">
                                  {value || "n/a"}
                                </span>
                              </li>
                            );
                        })}

                        {Object.keys(brand).map(key => {
                          const value = brand[key];

                          // description is long usually so we place it at the end
                          // with w-100 class;
                          if (key === "description") {
                            return (
                              <li
                                className="sub-li-item list-item-key brand d-flex align-items-center"
                                style={{ flex: "100%" }}
                              >
                                {key}:{" "}
                                <span
                                  className="list-item-value text-bold w-100"
                                  style={{ maxWidth: "100%", color: "gray" }}
                                >
                                  {value || "n/a"}
                                </span>
                              </li>
                            );
                          }
                        })}
                      </ul>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="row">
          {loading && (
            <div className="col-12 p-0">
              <LinearProgress />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatch = {
  refreshToken,
  logout
};
export default connect(
  null,
  mapDispatch
)(Brands);

moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "%ds",
    ss: "%d seconds",
    m: "1m",
    mm: "%dm",
    h: "1 h",
    hh: "%d hours",
    d: "1 d",
    dd: "%d days",
    M: "1 month",
    MM: "%d months",
    y: "1 y",
    yy: "%d years"
  }
});
