import React from "react";
import "./App.css";
import { Route, Switch, withRouter } from "react-router-dom";

import Header from "./components/common/header";
import Footer from "./components/common/footer";
import Main from "./components/main";
import AboutUs from "./components/aboutus";
import Login from "./components/login";
import SignupView from "./components/signup";
import InfluencerDashboard from "./components/dashboards/influencer";
import Dashboard from "./components/dashboards";
import Contactus from "./components/contactus";
import Redirect from './components/redirect';
import Reset from './components/reset-password';
import RequestReset from "./components/request-reset";

function App(props) {
  let { pathname } = props.location;
  return (
    <div className="App">
      {!pathname.includes("/dashboard") && <Header />}
      <div style={{ minHeight: "100vh" }}>
        <Switch>
          <Route path="/calculate" render={() => <Main ignoreSearches />} />
          <Route path="/aboutus" component={AboutUs} />
          <Route path="/request-reset" component={RequestReset} />
          <Route path="/reset" component={Reset} />

          <Route path="/login" component={Login} />
          <Route path="/signup" component={SignupView} />
          <Route path="/contactus" component={Contactus} />
          <Route path="/dashboard/:vendor" component={Dashboard} />
          <Route path="/z/:user_slug/:url_slug" component={Redirect} />
          <Route path="/" component={Main} />
        </Switch>
      </div>
      <Footer />
    </div>
  );
}

export default withRouter(App);
