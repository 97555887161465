import jwt from "jsonwebtoken";

export function getInitialState() {
  const initialState = { loggedIn: false, accountType: "" };
  let storedJwt = localStorage.getItem("jwt");
  if (!storedJwt) return initialState;

  let decoded = jwt.decode(storedJwt);
  let now = Date.now().valueOf() / 1000;
  if (decoded.exp && decoded.exp < now) {
    return initialState;
  }
  console.log("decoded", decoded);
  initialState.loggedIn = true;
  initialState.accountType = decoded.user_type.type;
  initialState.username = decoded.username;
  initialState.userId = decoded.id;
  initialState.name = decoded.name;
  initialState.email = decoded.email;
  initialState.phone = decoded.phone;
  initialState.expires_on = decoded.exp;
  initialState.token = storedJwt;
  return initialState;
}

/**
 *
 * @param {String} position field name
 * @param {String} data field input
 */
export function validate(position, data) {
  switch (position) {
    case "password":
      if (data !== "" && data.length < 4) {
        return {
          errorMessage: "password must be at least 5 charachters",
          position
        };
      } else {
        return false;
      }
    case "username":
      if (data !== "" && data.length < 4) {
        return {
          errorMessage: "username must be at least 5 charachters",
          position
        };
      } else {
        return false;
      }
    case "name":
      if (data !== "" && (!data.includes(" ") || data.length < 5)) {
        return { errorMessage: "must be a full name", position };
      } else {
        return false;
      }
    default:
      return false;
  }
}

/**
 * returns expiration time in milliseconds
 * @param {Number} seconds
 */
export function getExpirationTime(seconds) {
  return new Date().getTime() + seconds * 1000;
}
