import React, { Component } from "react";
import PropTypes from "prop-types";

import "./dropDownTab.scss";

export default class DropDownTab extends Component {
  state = {
    dropDown: false
  };
  constructor(props) {
    super(props);
    this.dropDownRef = React.createRef();
  }

  static propType = {
    title: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.object)
  };

  componentDidMount() {
    this.setDropDownHeight();
  }
  componentDidUpdate(prevProps) {
    // sets new height if new member is added to list
    if (
      prevProps.children.props.data.length <
      this.props.children.props.data.length
    ) {
      this.setDropDownHeight();
    }
  }

  setDropDownHeight = (setState = true) => {
    const dropDownHeight = this.dropDownRef.current.scrollHeight;

    if (setState) {
      this.setState({ dropDownHeight });
    } else {
      return dropDownHeight;
    }
  };
  toggleDropDown = () => {
    const dropDownHeight = this.setDropDownHeight(false);
    this.setState({ dropDownHeight }, () => {
      this.setState({ dropDown: !this.state.dropDown });
    });
  };
  render() {
    const { title } = this.props;
    const { dropDown, dropDownHeight } = this.state;
    return (
      <div className="dropDownTab rounded d-flex flex-column text-left py-md-4 px-md-4 px-3 bg-white">
        <div
          className="dropDownTab-main py-3 d-flex justify-content-between align-items-center"
          onClick={this.toggleDropDown}
        >
          <span className="dropDownTab-title lead">{title}</span>
          {dropDown ? (
            <i className="fas fa-chevron-down fa-2x mr-md-4" />
          ) : (
            <i
              className="fas fa-chevron-up fa-2x mr-md-4"
              style={{ color: "#959595" }}
            />
          )}
        </div>
        <div
          ref={this.dropDownRef}
          style={{ height: dropDown ? dropDownHeight : 0 }}
          className={`dropDownTab-dropDown`}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}
