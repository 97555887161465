import React, { Component } from "react";
import Proptypes from "prop-types";
import axios from "axios";
import { withRouter } from "react-router-dom";

import "./getOffer.scss";
import GetOfferSearchBar from "../getOfferSearchBar";
import EstimateNumbers from "./estimateSection.view";
import PotentialOffers from "./potentialOffers.view";
import CustomOffer from "./customOffer";
import configs from "../../../configs/envVariables.js";
import account from "../../dashboards/influencer/account";

const ApiBaseURI = configs.REACT_APP_API_DOMAIN;

/**
 * to use this component with an external form input just provide a name prop
 */
class GetOffer extends Component {
  state = {
    prevPropName: "",
    loading: false,
    name: "", // channelId
    subscribersTotal: 0,
    last50avg: 0,
    estimate: 0,
    topics: [],
    channelError: false,
    viewCount: 0,
    videoCount: 0,
    showCustomOfferForm: false,
    referringUser: ""
  };
  static propTypes = {
    name: Proptypes.string,
    ignoreSearches: Proptypes.bool,
    hide: Proptypes.bool,
    onAccountTypeChange: Proptypes.func,
    accountType : Proptypes.number
  };

  static defaultProps = {
    ignoreSearches: true,
    hide: false
  };

  static getDerivedStateFromProps(props, state) {
    /* ensures state is not derived from props when change is
     * local to this component (when user is typing inside the
     * box instead of the main banner) */
    if (state.prevPropName !== props.name) {
      return {
        ...state,
        name: props.name,
        prevPropName: props.name
      };
    } else {
      return {};
    }
  }
  componentDidMount() {
    const { name } = this.state;
    if (!name) return;

    const queries = new URLSearchParams(this.props.location.search);
    const referringUser = queries.get("user");



    this.setState({ loading: true, referringUser }, async () => {
      const data = await this.getEstimate();
      if(data){
        this.setState(this.formatData(data))
      }else{
        console.log("error, setting channelError to true")
        this.setState({ channelError: true, loading: false });
      }
     
    });
  }

  handleInputChange = e => {
    this.setState({ name: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState(this.resetData(), async () => {
      let data = await this.getEstimate();
      
    });
  };

  handleAccountTypeChange = e => {
    this.props.onAccountTypeChange(e);
  };

  getEstimate = async () => {
    const { referringUser, name } = this.state;
    try {
      let response = await axios.post(
        `${ApiBaseURI}/youtube/channel/estimate`,
        {
          channelId: name,
          user: referringUser,
          accountType: Number(this.props.accountType)
        }
      );
      // used when admins are testing search
      if (!this.props.ignoreSearches) {
        // intentially not using await
        axios.post(`${ApiBaseURI}/search`, {
          search: name,
          user: referringUser
        });
      }
      let data = response.data;
      
      if (data) {
        this.setState({
          ...this.state,
          subscribersTotal: parseInt(data.channel.subscriber_count),
          last50avg: data.average_view_count,
          estimate: data.estimate,
          loading: false,
          channelError: false,
          viewCount: data.channel.view_count,
          videoCount: data.channel.video_count
      })
      return data
      } else{
        console.log("error, setting channelError to true")
        this.setState({ channelError: true, loading: false });
        return 
      }
      
    } catch (err) {
      console.error(err);
    }
  };

  formatData = data => {
    return {
      subscribersTotal: parseInt(data.channel.subscriber_count),
      last50avg: data.average_view_count,
      estimate: data.estimate,
      topics: data.topics,
      loading: false,
      channelError: false,
      viewCount: data.channel.view_count,
      videoCount: data.channel.video_count
    };
  };

  resetData = () => {
    return {
      subscribersTotal: 0,
      last50avg: 0,
      estimate: 0,
      topics: [],
      loading: true,
      channelError: false
    };
  };

  handleCustomOfferBtnClick = e => {
    e.preventDefault();

    this.setState({
      showCustomOfferForm: !this.state.showCustomOfferForm
    });
  };
  render() {
    const {
      loading,
      name,
      estimate,
      subscribersTotal,
      last50avg,
      topics,
      videoCount,
      viewCount,
      showCustomOfferForm,
      channelError
    } = this.state;
    const handleInputChange = this.handleInputChange;
    const { hide } = this.props;

    return (
      <div
        id="offer"
        className={`container text-white ${hide === true ? "hide" : ""}`}
      >
        <div className="pb-5">
          <div className="row">
            <div className="col-lg-10 mx-auto px-lg-5 pb-lg-3 pt-lg-4 pt-3 rounded-bottom shadow getoffer-wrapper">
              <div className="row mb-4">
                <div className="col mb-3 mb-lg-4">
                  <GetOfferSearchBar
                    input={name}
                    handleInputChange={handleInputChange}
                    handleSubmit={this.handleSubmit}
                    loading={loading}
                    accountType={this.props.accountType}
                    handleAccountTypeChange={this.handleAccountTypeChange}
                  />

                  {channelError && (
                    <div className="col-lg-8 mx-auto py-3 px-4 mt-2 rounded alert-warning text-left">
                      <p className="m-0">
                        We could not find a channel with that name. Please try you're channel @username or the full channel ID.
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <EstimateNumbers
                estimate={estimate}
                subscribersTotal={subscribersTotal}
                last50avg={last50avg}
                topics={topics}
                viewCount={viewCount}
                videoCount={videoCount}
              />
              <PotentialOffers
                handleCustomOfferBtnClick={this.handleCustomOfferBtnClick}
              />
              {showCustomOfferForm && <CustomOffer channelId={name} accountType={Number(this.props.accountType)} />}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(GetOffer);
