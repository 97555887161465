import React, { Component } from "react";
import SectionTitle from "../../../common/dashboard/elements/sectionTitle";
import LineChart from "../../../common/charts/lineChart";

import "./reports.scss";
import Soon from "../../../common/soon";

export default class Reports extends Component {
  render() {
    return (
      <div id="dash-reports" className="container-fluid mb-5">
        <div className="row mb-3 mb-md-5">
          <div className="col-12">
            <SectionTitle data="Reports" />
          </div>
          <div className="col-12 py-3">
            <Soon data="Reports with real data are" />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="mr-lg-4 mb-3 p-3 p-lg-5 bg-white lineChart-container">
              <LineChart
                label="Revenue"
                labels={["March", "April", "May", "June", "July"]}
                data={[0, 75, 95, 115, 150]}
                color={"rgb(67, 145, 48, 0.5)"}
              />
            </div>
          </div>
          <div className="col-lg-6 ">
            <div className="mr-lg-4 mb-3 p-3 p-lg-5 bg-white lineChart-container">
              <LineChart
                label="Offers accepted"
                labels={["March", "April", "May", "June", "July"]}
                data={[0, 2, 3, 2, 4]}
                color={"rgb(158, 54, 163, 0.5)"}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
