import React, { Component } from "react";
import DashCard from "../../../common/cards/dashCard";
import ReactTable from "react-table";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";

import "react-table/react-table.css";
import "./offersTable.scss";
import { LinearProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { reserveOffer } from "../../../../redux/actions/influencer.actions";
import Err from "../../../common/error";
import Success from "../../../common/success";
// import brands from "../../../../resources/brands.json";
import { getRandomInt } from "../../../../utils/math";
import configs from "../../../../configs/envVariables.js";

const ApiBaseURI = configs.REACT_APP_API_DOMAIN;

class Offers extends Component {
  state = {
    primaryAccount: {
      accountId: "",
      accountName: ""
    },
    brands: [],
    loadingData: true,
    error: false,
    estimate: 0,
    average_view_count: 0,
    offers: { columns: [], data: [] }
  };

  // declared inside class to access this
  offers = {
    columns: [
      {
        Header: "Brand",
        accessor: "name",
        headerClassName: "table-header-cell",
        Cell: props => (
          <a href={props.original.Link} target="_blank">
            {props.original.Name}
          </a>
        )
      },
      {
        Header: "Instant offer",
        accessor: "baseOffer",
        Cell: props => <span>${props.original.baseOffer}</span>
      },
      {
        Header: "Estimate",
        accessor: "estimate",
        Cell: props => <span>${props.original.estimate}</span>
      },
      {
        Header: "",
        accessor: "",
        Cell: props => (
          <button
            className="reserve-btn btn-success"
            onClick={() => this.handleReserveClick(props)}
          >
            Reserve
          </button>
        )
      }
    ],
    data: []
  };
  componentDidMount() {
    const offers = this.offers;

    axios
      .get(`${ApiBaseURI}/brand`)
      .then(res => {
        const brands = res.data;

        offers.data = enhanceOffers(brands);
        console.log("offers", offers);
        // if (window.innerWidth < 450) this.hideColumns(offers);

        this.setState({ offers, loadingData: false, brands }, () => {
          this.getEstimateAndSetState({ initialMount: true });
        });
      })
      .catch(err => {
        // todo: handle error
        console.error(err);
        this.setState({ loadingData: false });
      });
  }
  componentDidUpdate() {
    const { loadingData } = this.state;

    if (!loadingData) {
      this.getEstimateAndSetState();
    }
  }

  hideColumns = offers => {
    offers.columns.forEach((column, i) => {
      console.log(column);
      if (column.accessor === "estimate") {
        column.show = false;
      }
    });
  };

  getEstimateAndSetState = async (
    { initialMount } = { initialMount: false }
  ) => {
    const { primaryAccount, error } = this.state;
    const { PrimaryAccount: propsPrimaryAccount } = this.props;

    if (primaryAccount.accountId !== propsPrimaryAccount.accountId && !error) {
      if (!initialMount && !propsPrimaryAccount.accountId) {
        return this.setState({
          loadingData: false,
          error: "you need a valid channel ID to get estimates"
        });
      }
      try {
        // TODO: remove this
        // load existing estimates instead of using a token to make a new one
        const response = await axios.post(
          `${ApiBaseURI}/youtube/channel/estimates`,
          { channelId: propsPrimaryAccount.accountId }
        );
        this.setState({
          ...this.getLoadedState(response.data.data, propsPrimaryAccount)
        });
        // const response = await axios.post(
        //   `${ApiBaseURI}/youtube/channel/estimates`,
        //   { channelId: propsPrimaryAccount.accountId }
        // );
        // this.setState({
        //   ...this.getLoadedState(response.data.data, propsPrimaryAccount)
        // });
      } catch (err) {
        this.setState({
          loadingData: false,
          error:
            "Sorry, we could not load estimate. please ensure you have provided a valid channelId to get estimates: " +
            err.message
        });
      }
    }
  };

  getLoadedState = (data, propsPrimaryAccount) => {
    const { brands } = this.state;

    const brandsWithEstimate = brands.map(brand => {
      const brandWithEstimate = { ...brand };
      brandWithEstimate.estimate = Math.floor(
        this.randomizeEstimate(data.estimate, brand)
      );
      brandWithEstimate.baseOffer = parseFloat(
        (brandWithEstimate.estimate - getRandomInt(2, 10)).toFixed(2)
      );

      return brandWithEstimate;
    });

    return {
      primaryAccount: propsPrimaryAccount,
      brands: brandsWithEstimate,
      loadingData: false,
      offers: {
        ...this.state.offers,
        data: brandsWithEstimate
      },
      average_view_count: data.average_view_count
    };
  };

  randomizeEstimate = (estimate, brand) => {
    // between 0.2 - 0.01
    const random = parseFloat((Math.random() * (1.3 - 0.8) + 0.8).toFixed(4));
    let newEstimate = (estimate * random).toFixed(2);
    if (brand.name === "Volugio" || brand.name === "1800MEDIGAP") {
      newEstimate = (parseFloat(newEstimate) / 2.5).toFixed(2);
    }
    newEstimate = parseFloat(newEstimate);

    return newEstimate;
  };

  handleReserveClick = props => {
    this.props.reserveOffer(props.original, this.props.userId);
  };

  formatMoney = number => {
    return "$" + number.toLocaleString();
  };

  render() {
    const {
      offers,
      loadingData,
      brands,
      error,
      average_view_count
    } = this.state;
    const {
      reserveLoading,
      reserveSuccess,
      reserveError,
      reserved,
      justSignedup
    } = this.props;

    return (
      <div className="container-fluid">
        <div className="row d-flex flex-wrap mb-5">
          {justSignedup && (
            <div className="col-12">
              <Success
                title="Congratulations!"
                message="This is your dashboard and below is where you will view and reserve new offers"
              />
            </div>
          )}
          {error && (
            <div className="col-12">
              <Err message={error} />
            </div>
          )}
          <div className="col-md-4 mb-3">
            <DashCard
              color={"pink"}
              title="Available offers"
              loading={loadingData}
              body={getTotalOffers(brands)}
              formattingFn={this.formatMoney}
              meta="contact us for more offers"
            />
          </div>
          <div className="col-md-4 mb-3">
            <DashCard
              color="purple"
              title="Reserved Offers"
              loading={loadingData}
              formattingFn={this.formatMoney}
              body={getTotalReserved(reserved)}
              meta="Reserve more offers below"
            />
          </div>
          <div className="col-md-4 mb-3">
            <DashCard
              color="green-blue"
              title="Avg Views"
              loading={loadingData}
              formattingFn={null}
              body={average_view_count}
              meta="Averege views of last 50 videos"
            />
          </div>
        </div>
        {reserveLoading && <LinearProgress />}
        {reserveError && <Err message={"Sorry " + reserveError} />}
        {reserveSuccess && (
          <Success message="item reserved successfully. expect to hear from us very soon!" />
        )}
        <div className="row mb-5">
          <div className="col text-center">
            {loadingData && <LinearProgress />}
            <ReactTable
              columns={offers.columns}
              data={offers.data}
              minRows={5}
            />
          </div>
        </div>
      </div>
    );
  }
}

function enhanceOffers(offers) {
  return offers.map(offer => {
    let estimate = (offer.baseOffer || 0) + (offer.offerPerThousand || 0) * 100;

    return {
      ...offer,
      offerPerThousand: offer.offerPerThousand
        ? offer.offerPerThousand.toLocaleString()
        : 0,
      baseOffer: offer.baseOffer ? offer.baseOffer.toLocaleString() : 0,
      estimate: estimate.toLocaleString(),
      estimateInt: estimate
    };
  });
}

function getTotalReserved(reserved) {
  if (!reserved.length) return 0;

  return reserved.reduce((prev, curr) => (prev += curr.estimate || 0), 0);
}

function getTotalOffers(offers) {
  return offers.reduce((prev, curr) => (prev += curr.estimate || 0), 0);
}

const mapState = state => ({
  reserveLoading: state.influencer.reserveLoading,
  reserveSuccess: state.influencer.reserveSuccess,
  reserveError: state.influencer.reserveError,
  userId: state.auth.userId,
  reserved: state.influencer.reserved,
  PrimaryAccount: state.influencer.primaryAccount
});
const mapDispatch = {
  reserveOffer
};
export default connect(
  mapState,
  mapDispatch
)(Offers);
