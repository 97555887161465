import React, { Component } from "react";
import { connect } from "react-redux";

// TODO : make component redirect to source protectedComponent

/**
 * wrap any component you want to protect and provide the type of user allowed
 * as to the first function call: withLogin("Admin")(Dashboard);
 * user Type must start with a capital because api uses capitals.
 *
 * @param {String} componentType type of user to allow access to this component
 * e.g. if Admin is passed then only account with Admin as type are allowed.
 */
const withLoggedIn = componentType => ProtectedComponent => {
  class LoggedInOnly extends Component {
    componentDidMount() {
      const { loggedin, accountType, history, location } = this.props;

      if (!loggedin) {
        history.push("/login", {
          redirect: location.pathname || "/"
        });
      } else if (componentType !== accountType && componentType) {
        history.push("/login");
      }
    }

    componentDidUpdate() {
      const { loggedin, accountType, history, location } = this.props;

      if (!loggedin) {
        history.push("/login", {
          redirect: location.pathname || "/"
        });
      } else if (componentType !== accountType && componentType) {
        history.push("/login");
      }
    }
    render() {
      return <ProtectedComponent {...this.props} />;
    }
  }
  const mapState = state => {
    return {
      loggedin: state.auth.loggedIn,
      accountType: state.auth.accountType
    };
  };
  return connect(mapState)(LoggedInOnly);
};

export default withLoggedIn;
