/**
 * Using React hooks in this component (instead of class components).
 */
import React, { useState } from "react";
import Select from "react-select";

import { Formik, Form, Field, ErrorMessage } from "formik";
import { CircularProgress } from "@material-ui/core";
import * as yup from "yup";

import Success from "../../../common/success";
import axios from "../../../../utils/axios";
import configs from "../../../../configs/envVariables.js";
import Err from "../../../common/error";
import { countriesOptions } from "../../../../utils/utils";

import "./criteriaForm.scss";

const ApiBaseURI = configs.REACT_APP_API_DOMAIN;

const validationSchema = yup.object().shape({
  countries: yup.array(),
  categories: yup.array(),
  minSubscribers: yup.number().required(),
  minViews: yup.number().required(),
  likeToDislike: yup.number().required(),
  maxInfluencerPayout: yup.number(),
  budget: yup.number().required(),
  productsPerVideo: yup.number(),
  bonusType: yup.string().required(),
  bonus_percentage: yup.number().required()
});

// we could pull options from API, but since they are const
// we save api calls this way
const categoryOptions = [
  { value: "Sports", label: "Sports" },
  { value: "Lifestyle", label: "Lifestyle" },
  { value: "Music", label: "Music" },
  { value: "Gaming", label: "Gaming" },
  { value: "Entertainment", label: "Entertainment" },
  { value: "Other", label: "Other" }
];

const bonusTypes = {
  sale_percentage: [
    {
      question: "Percentage amount (%)",
      value: "",
      key: "bonus_percentage"
    }
  ],
  upfront_plus_percentage: [
    {
      question: "Upfront amount ($)",
      value: "",
      key: "bonus_upfront"
    },
    {
      question: "Percentage amount (%)",
      value: "",
      key: "bonus_percentage"
    }
  ]
};

const bonusTypesLabels = {
  sale_percentage: "Sale percentage",
  upfront_plus_percentage: "Upfront amount"
};

const countryOptions = countriesOptions();

const initialValues = {
  bonusType: {},
  likeToDislike: 50,
  minViews: 0,
  minSubscribers: 0,
  maxInfluencerPayout: "",
  productsPerVideo: 1,
  budget: "",
  comment: "",
  countries: [countryOptions[229]],
  categories: []
};

export default ({ handleFormSuccess }) => {
  const [success, setSuccess] = useState(false);
  const [submitError, setSubmitError] = useState({ error: false, message: "" });
  const [bonusType, setBonusType] = useState("");

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting, resetForm, setFieldError }) => {
        try {
          setSubmitting(true);

          // await axios.post(`${ApiBaseURI}/admin`, {
          //   ...values,
          // });

          setSuccess(true);
          setSubmitError({ error: false, message: "" });

          handleFormSuccess(values);
          resetForm(initialValues);
        } catch (err) {
          const errorMessage =
            err.response && err.response.data
              ? err.response.data.error
              : err.message;
          setSubmitError({ error: true, message: errorMessage });
        }
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, values, setFieldValue }) => {
        if (values.bonusType !== bonusType) setBonusType(values.bonusType);

        return (
          <Form id="criteriaForm">
            <div className="form-row">
              <div className="form-group mb-4 col-md-8">
                <label htmlFor="minSubscribers">
                  Minimum Subscribers:{" "}
                  <span className="lead range-value mx-3">
                    {values.minSubscribers
                      ? values.minSubscribers.toLocaleString()
                      : 0}
                  </span>
                </label>
                <Field
                  type="range"
                  className="slider form-control px-0"
                  id="minSubscribers"
                  name="minSubscribers"
                  min="0"
                  max="500000"
                  step="1000"
                />
                <ErrorMessage name="minSubscribers">
                  {msg => <span className="text-danger">{msg}</span>}
                </ErrorMessage>
              </div>
              <div className="form-group mb-4 col-md-8">
                <label htmlFor="minViews">
                  Minimum Avg. Views per video:{" "}
                  <span className="lead range-value mx-3">
                    {values.minViews ? values.minViews.toLocaleString() : 0}
                  </span>
                </label>
                <Field
                  type="range"
                  className="slider form-control px-0"
                  id="minViews"
                  name="minViews"
                  min="0"
                  max="200000"
                  step="1000"
                />
                <ErrorMessage name="minViews">
                  {msg => <span className="text-danger">{msg}</span>}
                </ErrorMessage>
              </div>
              <div className="form-group mb-4 col-md-8">
                <label htmlFor="likeToDislike">
                  Minimum ratio of Likes to Dislikes:{" "}
                  <span className="lead range-value mx-3">
                    %
                    {values.likeToDislike
                      ? values.likeToDislike.toLocaleString()
                      : 0}
                  </span>
                </label>
                <Field
                  type="range"
                  className="slider form-control px-0"
                  id="likeToDislike"
                  name="likeToDislike"
                  min="0"
                  max="100"
                  step="1"
                />
                <ErrorMessage name="likeToDislike">
                  {msg => <span className="text-danger">{msg}</span>}
                </ErrorMessage>
              </div>
              <div className="form-group mb-4 col-md-8">
                <label className="mr-sm-2" htmlFor="account-type">
                  Categories
                </label>
                <Select
                  isMulti
                  name="categories"
                  options={categoryOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Select categories of interest..."
                  onChange={selected =>
                    setFieldValue("categories", selected || [])
                  }
                />
                <ErrorMessage name="categories">
                  {msg => <span className="text-danger">{msg}</span>}
                </ErrorMessage>
              </div>
              <div className="form-group mb-4 col-md-8">
                <label className="mr-sm-2" htmlFor="account-type">
                  Countries
                </label>
                <Select
                  isMulti
                  name="countries"
                  defaultValue={[countryOptions[229]]}
                  options={countryOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Select countries of interest..."
                  onChange={selected =>
                    setFieldValue("countries", selected || [])
                  }
                />
                <ErrorMessage name="countries">
                  {msg => <span className="text-danger">{msg}</span>}
                </ErrorMessage>
              </div>
              <div className="form-group mb-4 col-md-6">
                <label htmlFor="budget">Budget ( Dollar )</label>
                <Field
                  type="number"
                  className="form-control"
                  id="budget"
                  name="budget"
                  placeholder="Enter total budget amount.."
                  style={{ color: "green", fontWeight: 400 }}
                />
                <ErrorMessage name="budget">
                  {msg => <span className="text-danger">{msg}</span>}
                </ErrorMessage>
              </div>
              <div className="col-12" />
              <div className="form-group mb-4 col-md-6">
                <label htmlFor="maxInfluencerPayout">
                  Max. pay out per influencer
                </label>
                <Field
                  type="number"
                  className="form-control"
                  id="maxInfluencerPayout"
                  name="maxInfluencerPayout"
                  placeholder="Enter max dollar amount per influencer.."
                  style={{ color: "green", fontWeight: 400 }}
                />
                <ErrorMessage name="maxInfluencerPayout">
                  {msg => <span className="text-danger">{msg}</span>}
                </ErrorMessage>
              </div>
              <div className="form-group mb-4 col-md-7">
                <label htmlFor="productsPerVideo">
                  Number of products advertised per video (youtube)
                </label>
                <Field
                  type="number"
                  className="form-control"
                  id="productsPerVideo"
                  name="productsPerVideo"
                  placeholder="Enter number of products you want advertised per video.."
                  style={{ color: "green", fontWeight: 400 }}
                />
                <ErrorMessage name="productsPerVideo">
                  {msg => <span className="text-danger">{msg}</span>}
                </ErrorMessage>
              </div>
              <div className="form-group mb-4 col-md-7">
                <label htmlFor="bonusType">Referral bonus type</label>
                <Field
                  component="select"
                  className="form-control"
                  id="bonusType"
                  name="bonusType"
                  onChange={e => {
                    setFieldValue("bonusType", {
                      value: e.target.value,
                      label: bonusTypesLabels[e.target.value]
                    });
                  }}
                  value={values.bonusType.value}
                  style={{ fontWeight: 400 }}
                >
                  <option value="">Select bonus type</option>
                  <option value="sale_percentage">Sales percentage</option>
                  <option value="upfront_plus_percentage">
                    Upfront plus percentage
                  </option>
                </Field>
                <ErrorMessage name="bonusType">
                  {msg => <span className="text-danger">{msg}</span>}
                </ErrorMessage>
              </div>
              {bonusType.value &&
                bonusTypes[bonusType.value].map(question => (
                  <div key={question.key} className="form-group mb-4 col-md-7">
                    <label htmlFor="productsPerVideo">
                      {question.question}?
                    </label>
                    <Field
                      type="number"
                      className="form-control"
                      id={question.key}
                      name={question.key}
                      placeholder={`Enter ${question.question}..`}
                      style={{ color: "green", fontWeight: 400 }}
                    />
                    <ErrorMessage name={question.key}>
                      {msg => <span className="text-danger">{msg}</span>}
                    </ErrorMessage>
                  </div>
                ))}
              <div className="form-group mb-4 col-md-12">
                <label htmlFor="comment">Comment</label>
                <Field
                  component="textarea"
                  className="form-control"
                  id="comment"
                  name="comment"
                  placeholder="add any more details about what you are looking for.."
                />
                <ErrorMessage name="comment">
                  {msg => <span className="text-danger">{msg}</span>}
                </ErrorMessage>
              </div>
              <button
                type="submit"
                className="btn btn-primary mx-2 px-4 shadow"
                disabled={isSubmitting}
              >
                Submit Criteria
              </button>
            </div>
            {isSubmitting && (
              <span style={{ color: "#007bff" }}>
                <CircularProgress color="inherit" size="23px" />
              </span>
            )}
            {success && (
              <div className="py-2">
                <Success message="Criteria was added successfuly!" />
              </div>
            )}
            {submitError.error && <Err message={submitError.message} />}
          </Form>
        );
      }}
    </Formik>
  );
};
