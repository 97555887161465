import axios from "axios";
import configs from "../configs/envVariables.js";

const ApiBaseURI = configs.REACT_APP_API_DOMAIN;

export const getEstimate = async channelId => {
  try {
    const response = await axios.post(
      `${ApiBaseURI}/youtube/channel/estimate`,
      {
        channelId: channelId
      }
    );
    if (!response.data.success) return false;

    return response.data.data.estimate;
  } catch (err) {
    return false;
  }
};
