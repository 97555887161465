import React, { Component } from "react";

import Banner from "./banner/banner";
import Info from "./info/info";
import Samples from "./samples";
import Influencers from "./influencers";
import Opportunities from "./opportunities";
import Connect from "./connect";

export default class Main extends Component {
  render() {
    const { ignoreSearches } = this.props;
    return (
      <main className="container-fluid pb-5 mb-5">
        <Banner ignoreSearches={ignoreSearches} />
        <Info />
        <Samples />
        <Influencers />
        <Opportunities />
        <Connect />
      </main>
    );
  }
}
