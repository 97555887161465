import React from "react";

import "./footer.scss";

export default function Footer() {
  return (
    <footer id="footer" className="container-fluid bg-dark text-light">
      <div className="row w-75 mx-auto py-5">
        <div className="col-lg-6 text-lg-left pl-lg-5 pb-3 pb-lg-0">
          Copyright &copy; 2023 ziigg | all rights reserved
        </div>
        <div className="col-lg-6 text-lg-right pl-lg-5">Powered by ziigg</div>
      </div>
    </footer>
  );
}
