import React, { Component } from "react";
import Chart from "chart.js";
import PropTypes from "prop-types";

export default class LineChart extends Component {
  chartRef = React.createRef();

  static propTypes = {
    data: PropTypes.array.isRequired,
    labels: PropTypes.array.isRequired,
    options: PropTypes.object,
    label: PropTypes.string,
    color: PropTypes.string
  };
  componentDidMount() {
    this.drawChart();
  }

  componentDidUpdate() {
    this.drawChart();
  }

  drawChart = () => {
    const myChartRef = this.chartRef.current.getContext("2d");

    new Chart(myChartRef, {
      type: "line",
      data: {
        //Bring in data
        labels: this.props.labels,
        datasets: [
          {
            backgroundColor: this.props.color || "rgb(67, 145, 48, 0.5)",

            pointHoverBorderWidth: 2,
            label: this.props.label || "Sales",
            data: this.props.data
          }
        ]
      },
      options: {
        ...this.props.options,
        responsive: true,
        scales: {
          xAxes: [
            {
              gridLines: {
                color: "rgba(0, 0, 0, 0)"
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                color: "rgba(0, 0, 0, 0.03)"
              }
            }
          ]
        }
      }
    });
  };
  render() {
    return (
      <div className="w-100">
        <canvas id="lineChart" ref={this.chartRef} />
      </div>
    );
  }
}
