import React from "react";
import { Link } from "react-router-dom";
import "./login.scss";
import { LinearProgress } from "@material-ui/core";
import Err from "../common/error";

export default function Login({
  username,
  password,
  loginError,
  loginLoading,
  handleSubmit,
  handleInputChange,
  showErrors
}) {
  return (
    <div id="login" className="container py-md-5">
      <form
        className="login-form mx-auto m-5 pt-md-5 text-left"
        onSubmit={handleSubmit}
        onChange={handleInputChange}
      >
        <div className="form-group">
          <label className="p-form-label" htmlFor="username">
            username
          </label>
          <input
            type="text"
            className={`form-control ${showErrors &&
              !username &&
              "form-control-error"}`}
            id="username"
            value={username}
            placeholder="username or email"
          />
        </div>
        <div className="form-group">
          <label className="p-form-label" htmlFor="password">
            Password
          </label>
          <input
            type="password"
            className={`form-control ${showErrors &&
              !password &&
              "form-control-error"}`}
            id="password"
            value={password}
            placeholder="Password"
          />
        </div>
        <button type="submit" className="btn btn-primary w-100">
          Login
        </button>
        {!loginError && loginLoading && <LinearProgress />}
        {loginError && !loginLoading && <Err message={loginError} />}
      </form>
      <hr className="w-75" />
      <p className="text-muted">
        Don't have an account yet?{" "}
        <Link to="/signup">click here to signup</Link>
      </p>
      <p className="text-muted">
        Forgot your password?{" "}
        <Link to="/reset">click here to reset</Link>
      </p>
    </div>
  );
}
