import axios from "axios";

let token = localStorage.getItem("jwt");

if (token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

axios.interceptors.request.use(
  config => {
    return config;
  },
  err => Promise.reject(err)
);
export default axios;
