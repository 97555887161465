import React, { Component } from "react";
import { Link } from "react-router-dom";
import { validate } from "../../utils/login.utils";

import "./signup.scss";
import RecursiveQuestions from "../common/recursiveQuestions";
import Err from "../common/error";
import { connect } from "react-redux";
import { signupBrand } from "../../redux/actions/auth.actions";
import { LinearProgress } from "@material-ui/core";
import Success from "../common/success";
import AddressForm from "../common/addressForm";
import CommonSignupQuestions from "../common/CommonSignupQuestions";
import FormField from "../common/formElements/formField";
import { getEstimate } from "../../utils/influencer.util";

const defaultState = {
  accountType: "",
  brandName: "",
  brandLink: "",
  brandPhone: "",
  description: "",
  username: "",
  email: "",
  phone: "",
  password: "",
  password2: "",
  passwordMatch: null,
  address: "",
  address2: "",
  city: "",
  country: "United States",
  region: "",
  zip: "",
  accounts: [],
  name: "",
  validChannelId: null,
  questions: [],
  showErrors: false,
  recursiveQuestionsErrors: false,
  error: false,
  errorPosition: null,
  message: null,
  requiredFields: [
    "brandName",
    "brandPhone",
    "description",
    "password",
    "password2",
    "passwordMatch",
    "phone",
    "email",
    "name",
    "username",
    "country",
    "region",
    "address",
    "city",
    "termsAccepted"
  ]
};

class SignupBrand extends Component {
  state = {
    ...defaultState,
    // passed by accountType component
    accountType: this.props.accountType
  };

  componentDidMount() {}

  static getDerivedStateFromProps(props, state) {
    // resets fields if redux action succeeds
    if (props.signupSuccess) {
      return {
        ...state,
        ...defaultState
      };
    }

    return {};
  }
  handleSubmit = e => {
    e.preventDefault();

    if (this.isMissingRequiredField()) {
      return this.setState({
        error: true,
        errorPosition: null,
        errorMessage: "Please Fill required fields",
        showErrors: true
      });
    } else if (this.state.error || this.state.recursiveQuestionsErrors) {
      return this.setState({
        errorPosition: null,
        showErrors: true
      });
    }

    this.props.signupBrand({ ...this.state }, this.props.history);
  };

  handleInputChange = e => {
    let obj = JSON.parse(JSON.stringify(this.state));

    const shouldSetState = this._setAndValidateInputs(obj, e);

    // in some cases (like address components), state is handled and updated
    // in a different callback. in this case there is no need
    // to update state from here. _setAndValidateInputs will return
    // false if input field is not relevant.
    shouldSetState && this.setState(obj);
  };

  handleSelectCountry = val => {
    this.setState({ country: val });
  };
  handleSelectRegion = val => {
    this.setState({ region: val });
  };

  handleQuestionsChange = (error, questions) => {
    this.setState({ recursiveQuestionsErrors: error, questions });
  };

  /**
   * passed to FormField for validating
   * channelID
   */
  validateId = async value => {
    // TODO: add a new route to validate the channelid without estimate, the signup route will then get the data to make and store and estimate
    // that estimate can be remade evert so often
    this.setState({ validChannelId: true });
    // const estimate = await getEstimate(value);
    // if (estimate) {
    //   this.setState({ validChannelId: true });
    // } else {
    //   this.setState({ validChannelId: false });
    // }
  };

  /**
   * returns false if a required field is missing.
   */
  isMissingRequiredField = () => {
    const { requiredFields } = this.state;

    let isMissing = false;
    requiredFields.forEach(field => {
      if (!this.state[field]) {
        isMissing = true;
      }
    });

    return isMissing;
  };

  _setAndValidateInputs(obj, e) {
    let id = e.target.id;
    let value = e.target.value;
    let validation = { errorPosition: null };
    if (id === "") return false;

    /* the first if condition handles iput fields that have the same
     * property name in state as their Id name. for instance if input field has
     * an id of "email" and its respective field name in the state
     * is "email" also then we do obj[id] = value. So you only need
     * to add the id name in the array below. otherwise we create
     * a separate else if condition */
    if (
      [
        "email",
        "name",
        "username",
        "phone",
        "password",
        "address",
        "address2",
        "city",
        "brandName",
        "description",
        "brandLink",
        "brandPhone"
      ].includes(id)
    ) {
      validation = validate(id, value);
      obj[id] = value;
    } else if (id === "password2") {
      let passwordMatch =
        value === "" ? null : this.state.password !== value ? false : true;
      obj.passwordMatch = passwordMatch;
      obj.password2 = value;
      if (!passwordMatch) {
        validation = { errorMessage: "passwords do not match", position: id };
      }
    } else if (id === "state") {
      obj.region = value;
    } else if (id === "zipcode") {
      obj.zip = value;
    } else if (id === "agreeToTerms") {
      if (e.target.checked) {
        obj.termsAccepted = true;
      } else {
        obj.termsAccepted = false;
      }
    } else if (id === "channelId") {
      if (value) {
        obj.accounts = [
          {
            platform: "Youtube",
            id: value
          }
        ];
      } else {
        obj.accounts = [];
      }
    } else {
      return false;
    }

    if (typeof validation.errorMessage === "string") {
      obj.error = true;
      obj.errorMessage = validation.errorMessage;
      obj.errorPosition = validation.position;
    } else {
      obj.error = false;
      obj.errorMessage = "";
      obj.errorPosition = null;
    }

    return obj;
  }

  render() {
    const { signupLoading, signupSuccess, signupError } = this.props;
    const {
      requiredFields,
      brandName,
      description,
      error,
      showErrors,
      errorMessage,
      errorPosition,
      questions,
      country,
      region,
      name,
      phone,
      email,
      password,
      password2,
      passwordMatch,
      username,
      address,
      address2,
      city,
      zip,
      brandLink,
      termsAccepted,
      brandPhone
    } = this.state;

    return (
      <div id="signup" className="py-md-5 pb-5">
        <h3 className="h1 text-muted lead">Brand Signup</h3>
        <form
          className="signup-form mx-auto my-md-5 pt-4 text-left"
          onSubmit={this.handleSubmit}
          onChange={this.handleInputChange}
        >
          <p className="lead text-muted text-center mb-4 pt-3 mt-5">Brand</p>
          <FormField
            id="brandName"
            value={brandName}
            question="Brand Name"
            required={requiredFields.includes("brandName")}
            showErrors={showErrors}
            error={error}
            errorPosition={errorPosition}
            errorMessage={errorMessage}
          />
          <FormField
            id="brandLink"
            value={brandLink}
            question="Brand Website link"
            required={requiredFields.includes("brandLink")}
            showErrors={showErrors}
            error={error}
            errorPosition={errorPosition}
            errorMessage={errorMessage}
          />
          <FormField
            id="brandPhone"
            value={brandPhone}
            question="Contact number"
            required={requiredFields.includes("brandPhone")}
            showErrors={showErrors}
            error={error}
            errorPosition={errorPosition}
            errorMessage={errorMessage}
          />
          <FormField
            type="textaria"
            id="description"
            value={description}
            question="Brand description"
            required={requiredFields.includes("description")}
            showErrors={showErrors}
            error={error}
            errorPosition={errorPosition}
            errorMessage={errorMessage}
          />

          <p className="lead text-muted text-center mb-4 pt-3 mt-5">
            Account Holder
          </p>

          <CommonSignupQuestions
            username={username}
            name={name}
            password={password}
            password2={password2}
            email={email}
            passwordMatch={passwordMatch}
            showErrors={showErrors}
            error={error}
            errorPosition={errorPosition}
            errorMessage={errorMessage}
            phone={phone}
          />

          <p className="lead text-muted text-center mb-4 pt-3 mt-5">Address</p>
          <AddressForm
            address={address}
            country={country}
            address2={address2}
            region={region}
            zip={zip}
            city={city}
            showErrors={showErrors}
            handleInputChange={this.handleInputChange}
            handleSelectCountry={this.handleSelectCountry}
            handleSelectRegion={this.handleSelectRegion}
          />

          {questions.length !== 0 && (
            <p className="lead text-muted text-center mb-4 pt-3 mt-5">
              More Details
            </p>
          )}
          <RecursiveQuestions
            questions={questions}
            showErrors={showErrors}
            handleQuestionsChange={this.handleQuestionsChange}
          />

          <div
            className={`form-group pt-4 mb-0 form-check ${showErrors &&
              !termsAccepted &&
              "form-control-error"}`}
          >
            <input
              type="checkbox"
              className="form-check-input"
              id="agreeToTerms"
            />
            <label className={`form-check-label`} htmlFor="agreeToTerms">
              Check if you agree to{" "}
              <a
                href="/docs/terms/termsAgreement-7-10-2019.pdf"
                target="_blank"
              >
                terms of service
              </a>
            </label>
          </div>

          <button type="submit" className="btn btn-primary w-100 mt-4">
            Signup
          </button>
          {error && showErrors && (
            <Err title="Fix errors before submiting" message={errorMessage} />
          )}
          {!error && signupLoading && <LinearProgress />}
          {!error && signupSuccess && (
            <Success message="signed up successfully! now go login" />
          )}
          {!error && signupError && <Err message={signupError} />}
        </form>
        <hr className="w-75" />
        <p className="text-muted">
          Already have an account? <Link to="/login">click here to Login</Link>
        </p>
      </div>
    );
  }
}

const mapState = state => {
  return {
    signupLoading: state.auth.signupLoading,
    signupSuccess: state.auth.signupSuccess,
    signupError: state.auth.signupError
  };
};

const mapDispatch = {
  signupBrand
};

export default connect(
  mapState,
  mapDispatch
)(SignupBrand);
