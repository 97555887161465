import React, { Component } from 'react';
import RequestResetView from './request.view.js';
import { connect } from 'react-redux';
import { RequestRestPassword } from '../../redux/actions/auth.actions'; // Adjust the path accordingly
import { withRouter } from 'react-router-dom';

class RequestReset extends Component {
    state = {
        email: '',
        error: null
    };

    handleInputChange = (e) => {
        this.setState({ email: e.target.value });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { email } = this.state;

        // Validate email
        if (!email) {
            this.setState({ error: 'Please enter a valid email address' });
            return;
        }

        // Dispatch the action to request password reset
        this.props.RequestRestPassword(email, this.props.history);
    }

    render() {
        const { email, error } = this.state;
        return (
            <RequestResetView
                email={email}
                onEmailChange={this.handleInputChange}
                onSubmit={this.handleSubmit}
                error={error}
            />
        );
    }
}

const mapDispatchToProps = {
    RequestRestPassword
};

export default withRouter(connect(null, mapDispatchToProps)(RequestReset));
