import React, { Component } from "react";
import SectionTitle from "../elements/sectionTitle";
import Soon from "../../soon";

export default class Notifications extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 pb-4">
            <SectionTitle data="Notifications" />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Soon data="Notifications section is" />
          </div>
        </div>
      </div>
    );
  }
}
