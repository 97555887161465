import React, { Component } from "react";
// import Spinner from "@bit/bondz.react-epic-spinners.semipolar-spinner";

// import SignupSuccess from "./signupSuccess.view";
import { connect } from "react-redux";
import { signupInfluencer } from "../../redux/actions/auth.actions";
import SignupInfluencer from "./signupInfluencer";
import SignupBrand from "./signupBrand";
import AccountType from "../common/accountType/index.js";
import { Divider } from "@material-ui/core";

// import { spinner } from "../../styles/main/searchbox.module.scss";
class Signup extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="my-md-5 my-4 container">
        <AccountType>
          {props => {
            if (props.accountType === "Influencer")
              return <SignupInfluencer {...props} />;

            return <SignupBrand {...props} />;
          }}
        </AccountType>
      </div>
    );
  }
}

let mapDispatchToProps = {
  signupInfluencer
};

const mapStateToProps = state => {
  return {
    signupLoading: state.auth.signupLoading,
    signupSuccess: state.auth.signupSuccess,
    loginError: state.auth.signupError
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Signup);
