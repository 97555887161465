import {
  RESERVE_LOADING,
  RESERVE_SUCCESS,
  RESERVE_ERROR,
  ADD_ACCOUNTS,
  LOGOUT
} from "../types";

const defaultState = {
  reserveLoading: false,
  reserveSuccess: false,
  reserveError: false,
  reserved: [],
  accounts: { verified: {}, unverified: {} },
  primaryAccount: { accountId: "", accountName: "" }
};

export default (state = defaultState, action) => {
  const { type, payload } = action;
  state = JSON.parse(JSON.stringify(state));

  if (type === RESERVE_LOADING) {
    return {
      ...state,
      reserveLoading: true,
      reserveSuccess: false,
      reserveError: false
    };
  } else if (type === RESERVE_SUCCESS) {
    state.reserved.push(payload.offer);
    return {
      ...state,
      reserveLoading: false,
      reserveSuccess: true,
      reserveError: false,
      reserved: state.reserved
    };
  } else if (type === RESERVE_ERROR) {
    const { message } = payload;
    return {
      ...state,
      reserveLoading: false,
      reserveSuccess: false,
      reserveError: message || "failed to reserve offer"
    };
  } else if (type === ADD_ACCOUNTS) {
    const primaryAccount = getPrimaryAccount(payload);
    return {
      ...state,
      accounts: payload,
      primaryAccount: {
        accountId: primaryAccount.accountId,
        accountName: primaryAccount.accountName
      }
    };
  } else if (type === LOGOUT) {
    return { ...defaultState };
  }
  return state;
};

function getPrimaryAccount(accounts) {
  return {
    accountId:
      accounts.unverified.youtube[0] &&
      accounts.unverified.youtube[0].youtube_id,
    accountName:
      accounts.unverified.youtube[0] &&
      accounts.unverified.youtube[0].channel_name
  };
}
