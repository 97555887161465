import React from "react";

import "./contactus.scss";

export default function Contactus() {
  return (
    <div id="contactus" className="container">
      <div className="row py-5 px-2">
        <h2 className="col-12 lead pb-4">CONTACT US</h2>
        <div className="contactus-info-container col-md-6 mx-auto px-md-5 py-md-5 py-4 px-4 text-left text-white shadow">
          <p className="lead pb-4">
            Feel free to contact us with any questions you have.
          </p>
          <p className="h6">
            EMAIL - {"  "}
            <a href="mailto:webmaster@example.com" className="text-white lead">
              jeff.cline@me.com
            </a>
          </p>
          <p className="h6">
            PHONE - {"  "}
            <a href="tel:1-972-800-6670" className="text-white lead">
              972-800-6670
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
