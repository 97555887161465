import React from "react";
import CountUp from "react-countup";

const addCommaToNumber = n => n.toLocaleString();

export default function EstimateNumbers({
  subscribersTotal,
  estimate,
  last50avg,
  topics,
  viewCount,
  videoCount
}) {
  return (
    <div className="row justify-content-center mb-5 mb-lg-2">
      <div className="col-lg-4 col-11 mx-lg-5 mb-lg-3">
        <div className="d-flex flex-column mb-4 mb-lg-0">
          <p className="offer-display-title mb-0 pb-2">Total Subscribers</p>
          <p className="offer-display-number rounded">
            {subscribersTotal > 0 ? (
              <CountUp
                formattingFn={addCommaToNumber}
                end={subscribersTotal}
                start={0}
                duration={1.5}
              />
            ) : (
              0
            )}
          </p>
        </div>
      </div>
      <div className="col-lg-4 col-11 mx-lg-5 mb-lg-3">
        <div className="d-flex flex-column mb-4 mb-lg-0">
          <p className="offer-display-title mb-0 pb-2">Total channel views</p>
          <p className="offer-display-number rounded">
            {last50avg > 0 ? (
              <CountUp
                end={viewCount}
                start={0}
                duration={1.5}
                formattingFn={addCommaToNumber}
              />
            ) : (
              0
            )}
          </p>
        </div>
      </div>
      <div className="col-lg-4 col-11 mx-lg-5 mb-lg-3">
        <div className="d-flex flex-column mb-4 mb-lg-0">
          <p className="offer-display-title mb-0 pb-2">
            Last 50 videos avg views
          </p>
          <p className="offer-display-number rounded">
            {last50avg > 0 ? (
              <CountUp
                end={last50avg}
                start={0}
                duration={1.5}
                formattingFn={addCommaToNumber}
              />
            ) : (
              0
            )}
          </p>
        </div>
      </div>
      <div className="col-lg-4 col-11 mx-lg-5 mb-lg-3">
        <div className="d-flex flex-column mb-4 mb-lg-0">
          <p className="offer-display-title mb-0 pb-2">Number of videos</p>
          <p className="offer-display-number rounded">
            {last50avg > 0 ? (
              <CountUp
                end={videoCount}
                start={0}
                duration={1.5}
                formattingFn={addCommaToNumber}
              />
            ) : (
              0
            )}
          </p>
        </div>
      </div>
      <div className="col-lg-8 mx-lg-5 pt-lg-2">
        <div className="d-flex flex-column mb-4 mb-lg-0">
          <div className="col-lg-6 mx-auto">
            <p className="offer-display-title mb-0 pb-2">Channel topics</p>
          </div>
          <div className="d-flex flex-wrap justify-content-center pt-3">
            {Array.isArray(topics) && topics.length > 0 ? (
              topics.map(topic => (
                <span
                  key={topic}
                  className="topic align-self-center mx-1 shadow px-3 py-1 mb-2"
                >
                  {topic}
                </span>
              ))
            ) : (
              <span className="topic align-self-center mx-1 shadow px-3 py-1 mb-2">
                none
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="col-12 pt-lg-4 pb-lg-5 mt-5">
        <div className="shadow estimate-wrapper px-4 mx-auto px-lg-5">
          <h4 className="estimate-title mx-auto mb-0 pt-2">ESTIMATE</h4>
          <p className="estimate-number mx-auto pb-4">
            $
            {estimate > 0 ? (
              <CountUp
                end={estimate}
                start={0}
                duration={4}
                formattingFn={addCommaToNumber}
              />
            ) : (
              0
            )}
            <span
              style={{
                fontSize: "1rem",
                marginLeft: 5,
                fontWeight: 300
              }}
            >
              /video
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
