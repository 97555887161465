import React from "react";
import Proptypes from "prop-types";

import "./membersList.scss";

export default function TeamList({ data }) {
  return (
    <div id="teamlist">
      <ul className="teamlist-list">
        {data.map(member => (
          <li key={member.email} className="teamlist-item p-3 rounded mb-2">
            <div className="d-flex flex-column">
              <div className="d-flex justify-content-start">
                <span className="lead teamlist-item-title">{member.name}</span>
              </div>
              <div className="d-flex justify-content-start flex-wrap">
                <span className="lead teamlist-item-metric pending">
                  Email: <span className="item-value">{member.email}</span>
                </span>
                <span className="lead teamlist-item-metric pending">
                  Phone: <span className="item-value">{member.phone}</span>
                </span>
                <span className="lead teamlist-item-metric finished">
                  Id: <span className="item-value">{member.id}</span>
                </span>
                <span className="lead teamlist-item-metric region">
                  Region: <span className="item-value">{member.region}</span>
                </span>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

TeamList.propTypes = {
  data: Proptypes.arrayOf(
    Proptypes.shape({
      name: Proptypes.string,
      pendingTasks: Proptypes.array,
      finishedTasks: Proptypes.array,
      region: Proptypes.string
    })
  ).isRequired
};

function getTotalPendingCash(tasks) {
  return tasks
    .reduce((prev, current) => (prev += current.cashPending), 0)
    .toLocaleString();
}
