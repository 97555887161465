import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";

import "./dashboardNavbar.scss";

class InfluencerDashNavbar extends Component {
  handleExitClick = e => {
    e.preventDefault();

    this.props.history.push("/");
  };
  render() {
    const {
      navbarRef,
      username,
      toggleLeftNav,
      handleSectionClick
    } = this.props;
    return (
      <nav
        id="dashboard-navbar"
        ref={navbarRef}
        className="navbar navbar-expand navbar-light fixed-top bg-white"
      >
        <a
          className="navbar-brand d-flex ml-lg-4 align-items-center"
          href="#"
          onClick={toggleLeftNav}
        >
          <div className="logo-icon">
            <img src="/imgs/logo.png" className="img-fluid" alt="" />
          </div>
          <div className=" logo-text">Influencer</div>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item dropdown d-flex align-items-center">
              <div
                className="d-flex align-items-center"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="false"
                aria-expanded="true"
              >
                <div className="user-avatar">
                  <img
                    src="/imgs/user-avatar.png"
                    alt="user avatar"
                    className="user-avatar-img img-fluid"
                  />
                </div>
                <span className="nav-link dropdown-toggle pl-2 username">
                  {username}
                </span>
              </div>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a
                  className="dropdown-item"
                  href="#"
                  data-section="dash-account"
                  onClick={handleSectionClick}
                >
                  account
                </a>
                <div className="dropdown-divider" />
                <a className="dropdown-item" href="#">
                  help
                </a>
              </div>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="#"
                data-section="dash-inbox"
                onClick={handleSectionClick}
              >
                <i className="far fa-envelope" />
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="#"
                data-section="dash-notifications"
                onClick={handleSectionClick}
              >
                <i className="far fa-bell" />
              </a>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link" onClick={this.handleExitClick}>
                <i className="fas fa-times-circle" />
              </a>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

const mapState = state => {
  return {
    username: state.auth.username
  };
};

export default connect(mapState)(withRouter(InfluencerDashNavbar));
