import axios from "../../utils/axios";
import { getExpirationTime } from "../../utils/login.utils";
import jwt from "jsonwebtoken";
import {
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  LOGOUT,
  LOGIN_ERROR,
  SIGNUP_LOADING,
  SIGNUP_ERROR,
  REFRESH_TOKEN
} from "../types";
import configs from "../../configs/envVariables.js";

const ApiBaseURI = configs.REACT_APP_API_DOMAIN;

export const signupInfluencer = (state, history) => async dispatch => {
  dispatch({ type: SIGNUP_LOADING, payload: null });

  let signupData = { ...state };

  formatSignupData(signupData);
  try {
    const response = await axios.post(`${ApiBaseURI}/influencer`, signupData);
    localStorage.setItem("jwt", response.data.access_token);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${response.data.access_token}`;
    dispatch({
      type: LOGIN_SUCCESS,
      payload: {
        token: response.data.access_token
      }
    });

    history.push("/dashboard/influencer", { justSignedup: true });
  } catch (err) {
    const errorMessage =
      err.response.data && err.response.data.error
        ? err.response.data.error
        : `signup failed with code ${err.response.status}, please try again or contact us`;
    dispatch({
      type: SIGNUP_ERROR,
      payload: { message: errorMessage }
    });
  }
};
export const signupBrand = (state, history) => async dispatch => {
  try {
    const brandData = extractBrandData(state);
    brandData.user = extractBrandUserData(state);

    const brandResponse = await axios.post(`${ApiBaseURI}/brand`, brandData);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: {
        token: brandResponse.data.access_token
      }
    });

    history.push("/dashboard/brand", { justSignedup: true });
  } catch (err) {
    // todo: handle error
    console.error(err);
  }
};

export const login = ({ username, password, history }) => async dispatch => {
  dispatch({ type: LOGIN_LOADING, payload: {} });

  try {
    let response = await axios.post(`${ApiBaseURI}/auth/login`, {
      username,
      password
    });

    if (response.status === 200) {
      localStorage.setItem("jwt", response.data.access_token);
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response.data.access_token}`;
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          token: response.data.access_token
        }
      });
      console.log("jwt", jwt.decode(response.data.access_token));
      let accountType = jwt.decode(response.data.access_token).user_type.type;
      accountType = accountType.toLowerCase();
      history.push(`/dashboard/${accountType}`);
    }
  } catch (err) {
    dispatch({ type: LOGIN_ERROR, payload: {} });
  }
};

export const logout = () => {
  localStorage.removeItem("jwt");
  return { type: LOGOUT, payload: {} };
};

export const refreshToken = () => async dispatch => {
  try {
    let response = await axios.get(`${ApiBaseURI}/auth/refresh`);

    localStorage.setItem("jwt", response.data.access_token);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${response.data.access_token}`;
    dispatch({
      type: REFRESH_TOKEN,
      payload: {
        token: response.data.access_token
      }
    });
  } catch (err) {
    console.error(err);
    return;
  }
};

export const RequestRestPassword = (email, history) => async dispatch => {
  try {
    const response = await axios.post(`${ApiBaseURI}/password/email`, {
      email
    });
    if (response.status === 200) {
      history.push("/login");
    }
  } catch (err) {
    console.error(err);
  }
}

export const ResetPassword = (password, token, history) => async dispatch => {
  try {
    const response = await axios.post(`${ApiBaseURI}/password/reset/`, {
      token: token,
      password: password,
      password_confirmation: password
    });
    if (response.status === 200) {
      history.push("/login");
      return Promise.resolve();
    } else {
      return Promise.reject('Failed to reset password');
    }
  } catch (err) {
    return Promise.reject("error");
  }
};

function formatSignupData(data) {
  delete data.error;
  delete data.errorPosition;
  delete data.errorMessage;
  delete data.showErrors;
  delete data.message;

  data.userType = data.accountType;
  data.address1 = data.address;

  delete data.address;
  delete data.accountType;
  delete data.password2;
  delete data.recursiveQuestionsErrors;
}

function extractBrandData(data) {
  return {
    name: data.brandName,
    description: data.description,
    link: data.brandLink || "n/a",
    logo: data.logo || "/imgs/brands/random.jpg",
    picture: data.picture || "/imgs/brands/random.jpg",
    phone: data.brandPhone || "n/a"
  };
}
function extractBrandUserData(data) {
  return {
    name: data.name,
    username: data.username,
    email: data.email,
    password: data.password,
    phone: data.phone,
    address1: data.address,
    address2: data.address2,
    city: data.city,
    region: data.region,
    zip: data.zip,
    country: data.country,
    questions: data.questions
  };
}
