import React from "react";

import "./error.scss";

export default function Err({ title, message, style }) {
  if (title && message) {
    return (
      <div className="alert alert-danger px-3" style={style}>
        <h5 className="error-title">{title}</h5>
        <p className="error-message text-left" style={{ marginLeft: -5 }}>
          {addLineBreak(message)}
        </p>
      </div>
    );
  }
  if (message || title) {
    return (
      <div className="alert alert-danger px-3" style={style}>
        {title || addLineBreak(message)}
      </div>
    );
  }

  return (
    <div className="alert aldanger" style={style}>
      error!
    </div>
  );
}

function addLineBreak(string) {
  return string.split(/(\r\n|\r|\n)/g).map(line =>
    line ? (
      <p key={line} className="text-left px-lg-2 px-md-3 mb-0">
        {line}
      </p>
    ) : null
  );
}
