import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import "./header.scss";
import { connect } from "react-redux";
import { logout } from "../../../redux/actions/auth.actions";

class Header extends Component {
  handleLogout = e => {
    e.preventDefault();

    this.props.logout();
    this.props.history.push("/");
  };
  render() {
    const { loggedIn } = this.props;
    return (
      <header className="container">
        <nav className="navbar navbar-expand-lg navbar-light px-1 py-3">
          <Link className="navbar-brand pl-md-4" to="/">
            <div className="flex-column text-left">
              <div className="navbar-logo-container">
                <img
                  src="/imgs/full-logo.png"
                  className="img-fluid"
                  alt="ziigg logo"
                />
              </div>
              <p className="display-4 logo-title mb-0">
                influencer marketing hub
              </p>
              <p className="logo-subtitle">connecting brands with artists</p>
            </div>
          </Link>
          <button
            className="navbar-toggler navbar-dark py-2"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav ml-auto mr-3">
              <li className="nav-item active">
                <Link className="nav-link" to="/">
                  Home <span className="sr-only">(current)</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/aboutus">
                  About us
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contactus">
                  Contact us
                </Link>
              </li>

              <li className="nav-item">
                {loggedIn && (
                  <Link
                    className="nav-link"
                    to={`/dashboard/${this.props.accountType.toLowerCase()}`}
                  >
                    Dashboard
                  </Link>
                )}
              </li>
              <li className="nav-item">
                {!loggedIn ? (
                  <Link className="nav-link" to="/login">
                    Login
                  </Link>
                ) : (
                  <div>
                    <Link
                      className="nav-link"
                      to="#"
                      onClick={this.handleLogout}
                    >
                      Logout
                    </Link>
                  </div>
                )}
              </li>
            </ul>
          </div>
        </nav>
      </header>
    );
  }
}

const mapState = state => {
  return {
    loggedIn: state.auth.loggedIn,
    accountType: state.auth.accountType
  };
};

const mapDispatch = {
  logout
};

export default connect(
  mapState,
  mapDispatch
)(withRouter(Header));

/**
 * TODO
 * make navbar sticky with a custom design
 */
