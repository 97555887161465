import React, { Component } from "react";

import "./dashboardLeftNavbar.scss";

export default class InfluencerLeftNavbar extends Component {
  render() {
    const { currentSelected = "dash-offers" } = this.props;
    return (
      <nav id="dash-left-navbar">
        <ul className="nav flex-column">
          <li
            data-section="dash-offers"
            onClick={this.props.handleSectionClick}
            className="nav-item"
          >
            <a
              className={`nav-link mb-2 ${currentSelected === "dash-offers" &&
                "active"}`}
              href="#"
            >
              Offers
            </a>
            <i className="fas fa-comment-dollar" />
          </li>
          <li
            data-section="dash-reports"
            onClick={this.props.handleSectionClick}
            className="nav-item"
          >
            <a
              className={`nav-link mb-2 ${currentSelected === "dash-reports" &&
                "active"}`}
              href="#"
            >
              Reports
            </a>
            <i className="fas fa-chart-line" />
          </li>
          <li
            data-section="dash-stats"
            onClick={this.props.handleSectionClick}
            className="nav-item"
          >
            <a
              className={`nav-link mb-2 ${currentSelected === "dash-stats" &&
                "active"}`}
              href="#"
            >
              Stats
            </a>
            <i className="far fa-clipboard" />
          </li>
          <li
            data-section="dash-banking"
            onClick={this.props.handleSectionClick}
            className="nav-item"
          >
            <a
              className={`nav-link mb-2 ${currentSelected === "dash-banking" &&
                "active"}`}
              href="#"
            >
              Banking
            </a>
            <i className="fas fa-university" />
          </li>
        </ul>
      </nav>
    );
  }
}
