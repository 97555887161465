import React, { Component } from 'react';
import ResetView from './reset.view';
import { connect } from 'react-redux';
import { ResetPassword } from '../../redux/actions/auth.actions';
import { withRouter } from 'react-router-dom';

class Reset extends Component {
    state = {
        password: '',
        confirmPassword: '',
        token: '',
        error: null,
        successMessage: '',
        errorMessage: ''
    };

    componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);
        const token = params.get('token');
        if (!token) {
            this.props.history.push('/request-reset');
        } else {
            this.setState({ token });
        }
    }

    handleInputChange = (e) => {
        console.log(e.target.name, e.target.value,'input change')
        this.setState({ [e.target.name]: e.target.value });
    }
    handleSubmit = (e) => {
        e.preventDefault();
        const { password, confirmPassword, token } = this.state;
        
        console.log(password, confirmPassword)

        if (password !== confirmPassword) {
            this.setState({ errorMessage: 'Passwords do not match', successMessage: '' });
            return;
        }
    
        this.props.ResetPassword(password, token, this.props.history)
            .then(() => {
                this.setState({ successMessage: 'Password successfully reset', errorMessage: '' });
            })
            .catch(error => {
                this.setState({ errorMessage: error || 'Failed to reset password', successMessage: '' });
            });
    }
    

    render() {
        const { password, confirmPassword, error,successMessage, errorMessage } = this.state;
        return (
            <ResetView
                password={password}
                confirmPassword={confirmPassword}
                onPasswordChange={this.handleInputChange}
                onConfirmPasswordChange={this.handleInputChange}
                onSubmit={this.handleSubmit}
                error={error}
                successMessage={successMessage}
                errorMessage={errorMessage}
            />
        );
    }
}

const mapDispatchToProps = {
    ResetPassword
};

export default withRouter(connect(null, mapDispatchToProps)(Reset));
