import axios from "../../utils/axios";
import { REFRESH_TOKEN, LOGOUT } from "../types";
import configs from "../../configs/envVariables.js";

const ApiBaseURI = configs.REACT_APP_API_DOMAIN;

export default store => next => async action => {
  const { getState, dispatch } = store;
  if (action.type === LOGOUT) return next(action);

  const { token, expires_on } = getState().auth;

  if (!token) return next(action);

  const timeNow = Date.now() / 1000;
  const timeLeftBeforeExpiration = expires_on - timeNow;

  if (timeLeftBeforeExpiration < 0) {
    return dispatch({ type: LOGOUT });
  }

  // refresh if expiration is in less than 30 min
  if (
    token &&
    timeLeftBeforeExpiration < 1800 &&
    action.type !== REFRESH_TOKEN
  ) {
    let response = await axios.get(`${ApiBaseURI}/auth/refresh`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getState().auth.token}`
      }
    });

    localStorage.setItem("jwt", response.data.access_token);
    axios.defaults.headers.common["Authorization"] = `Bearer ${
      response.data.access_token
    }`;
    dispatch({
      type: REFRESH_TOKEN,
      payload: {
        token: response.data.access_token,
        nextAction: { ...action }
      }
    });
    return next(action);
  }
  next(action);
};
