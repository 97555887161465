import React from "react";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import PropsTypes from "prop-types";

export default function AddressForm({
  showErrors,
  address,
  address2,
  zip,
  country,
  region,
  handleSelectCountry,
  handleSelectRegion,
  city
}) {
  return (
    <div>
      <div className="form-group">
        <label className="p-form-label" htmlFor="address">
          street address
        </label>
        <input
          type="text"
          className={`form-control ${!address &&
            showErrors &&
            "form-control-error"}`}
          id="address"
          value={address}
          onChange={function() {
            /* no-op, change handled by the parent component (Form) */
          }}
          placeholder="street address *"
        />
      </div>
      <div className="form-group">
        <label className="p-form-label" htmlFor="address2">
          street address 2
        </label>
        <input
          type="text"
          className="form-control"
          id="address2"
          value={address2}
          onChange={function() {
            /* no-op, change handled by the parent component (Form) */
          }}
          placeholder="street address 2"
        />
      </div>
      <div className="form-group">
        <label className="p-form-label" htmlFor="city">
          city
        </label>
        <input
          type="text"
          className="form-control"
          id="city"
          value={city}
          onChange={function() {
            /* no-op, change handled by the parent component (Form) */
          }}
          placeholder="City"
        />
      </div>
      <CountryDropdown
        style={{
          borderColor: "#dcdcdc",
          color: "#989898",
          maxWidth: 300,
          minHeight: 35
        }}
        value={country}
        onChange={val => handleSelectCountry(val)}
      />
      <div className="d-flex pt-2">
        <RegionDropdown
          style={{ borderColor: "#dcdcdc", color: "#989898", maxWidth: 300 }}
          country={country}
          // defaultOptionLabel="select state"
          value={region}
          onChange={val => handleSelectRegion(val)}
        />
        <div className="form-group mb-0 pl-2 flex-fill">
          <label className="p-form-label" htmlFor="zipcode">
            zip code
          </label>
          <input
            type="text"
            className={`form-control`}
            id="zipcode"
            style={{ height: 35 }}
            value={zip}
            onChange={function() {
              /* no-op, change handled by the parent component (Form) */
            }}
            placeholder="zipcode"
          />
        </div>
      </div>
    </div>
  );
}

AddressForm.propTypes = {
  address: PropsTypes.string.isRequired,
  address2: PropsTypes.string.isRequired,
  country: PropsTypes.string.isRequired,
  zip: PropsTypes.string.isRequired,
  region: PropsTypes.string.isRequired
};
