import React, { Component } from "react";

import "./team.scss";
import DropDownTab from "../../../common/dropDownTab";
import TeamList from "../../../common/teamList";
import TeamMemberForm from "./teamMemberForm";
import axios from "../../../../utils/axios";
import configs from "../../../../configs/envVariables.js";

const ApiBaseURI = configs.REACT_APP_API_DOMAIN;

export default class Team extends Component {
  state = {
    showAddMember: false,
    team: {
      admin: [],
      brand_rep: [],
      influencer_rep: []
    }
  };

  componentDidMount() {
    this.fetchTeam();
  }

  handleAddMemberClick = e => {
    e.preventDefault();
    this.setState({ showAddMember: !this.state.showAddMember });
  };
  handleNewMember = member => {
    const team = JSON.parse(JSON.stringify(this.state.team));

    team[member.accountType].push(member);

    this.setState({ team });
  };

  fetchTeam = async () => {
    try {
      const res = await axios.get(`${ApiBaseURI}/admin`);
      let team = res.data && res.data.data;

      team = this.categorizeTeams(team);

      this.setState({ team });
    } catch (err) {
      console.error(err);
    }
  };

  categorizeTeams = team => {
    const result = {
      admin: [],
      brand_rep: [],
      influencer_rep: []
    };

    team.forEach(member => {
      let memberType = member.user_type.type.toLowerCase();

      result[memberType].push(member);
    });

    return result;
  };
  render() {
    const { showAddMember, team } = this.state;
    return (
      <div id="dash-team" className="container-fluid">
        <div className="row mb-4">
          <div className="col-12 d-flex text-left">
            <a
              href=""
              onClick={this.handleAddMemberClick}
              className="team-add-btn shadow"
            >
              Add Member
            </a>
          </div>
          <div
            className={`col-12 team-add-wrapper text-left ${!showAddMember &&
              "hide"}`}
          >
            <div
              className={`team-add-container p-3 p-md-5 bg-white shadow-sm my-4`}
            >
              <TeamMemberForm handleNewMember={this.handleNewMember} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-3">
            <DropDownTab title="Admin">
              <TeamList data={team.admin} />
            </DropDownTab>
          </div>
          <div className="col-12 mb-3">
            <DropDownTab title="Brand's Rep">
              <TeamList data={team.brand_rep} />
            </DropDownTab>
          </div>
          <div className="col-12 mb-3">
            <DropDownTab title="Influencer's Rep">
              <TeamList data={team.influencer_rep} />
            </DropDownTab>
          </div>
        </div>
      </div>
    );
  }
}

const team = {
  content_creator: [
    {
      id: 1,
      name: "Tarek Demachkie",
      pendingTasks: [
        {
          pipelineId: 2,
          cashPending: 250,
          cashCleared: 100,
          dueDate: "08202019",
          region: "Dallas, Tx"
        }
      ],
      finishedTasks: [
        {
          pipelineId: 1,
          cashPending: 150,
          cashCleared: 100,
          dueDate: "08052019",
          finishData: "08012019",
          region: "Dallas, Tx"
        }
      ],
      region: "Dallas, Tx",
      phone: "972-670-1500",
      email: "tarek.demachkie@gmail.com",
      comment: "available only in the Dallas region."
    },
    {
      id: 2,
      name: "Danny Davis",
      pendingTasks: [
        {
          pipelineId: 3,
          cash: 600,
          cashPending: 350,
          cashCleared: 100,
          dueDate: "08202019",
          Region: "Singapore"
        }
      ],
      finishedTasks: [
        {
          pipelineId: 4,
          cash: 500,
          cashPending: 150,
          cashCleared: 100,
          dueDate: "08052019",
          finishData: "08012019",
          Region: "Japan"
        }
      ],
      region: "Dallas, Tx",
      phone: "972-670-1555",
      email: "danny.davis@gmail.com",
      comment: ""
    }
  ],
  sales_manager: [],
  sales_rep: []
};
