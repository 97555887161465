import React, { Component } from "react";
import CriteriaForm from "./criteriaForm";
import SectionTitle from "../../../common/dashboard/elements/sectionTitle";
import CriteriaDisplay from "./criteriaDisplay";

export default class Criteria extends Component {
  state = {
    criteria: null,
    // criteria: {
    //   bonusType: { value: "sale_percentage", label: "Bonus sale percentage" },
    //   bonus_percentage: 43,
    //   bonus_upfront: 23,
    //   budget: 5000,
    //   categories: [{ value: "Music", label: "Music" }],
    //   comment: "",
    //   countries: [{ value: "United States", label: "United States" }],
    //   likeToDislike: 50,
    //   maxInfluencerPayout: 1000,
    //   minSubscribers: 8000,
    //   minViews: 5000,
    //   productsPerVideo: 1
    // },
    showEditor: true
  };

  handleFormSuccess = criteria => {
    if (!criteria) return;

    this.setState({ criteria, showEditor: false });
  };
  render() {
    const { criteria, showEditor } = this.state;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <SectionTitle data="Criteria" />
          </div>
          {criteria && (
            <div className="col-12 bg-white p-3 p-md-5 my-4 rounded">
              <CriteriaDisplay {...criteria} />
            </div>
          )}
          {(!criteria || showEditor) && (
            <div className="col-12 bg-white p-3 p-md-5 my-4 rounded">
              <CriteriaForm handleFormSuccess={this.handleFormSuccess} />
            </div>
          )}
        </div>
      </div>
    );
  }
}
