import React from "react";

export default function Soon({ data }) {
  const containerStyle = {
    width: "inherit",
    maxWidth: 450,
    borderRadius: "5px",
    height: "fit-content",
    backgroundColor: "rgb(188, 101, 216)",
    margin: "5px auto",
    backgroundImage:
      "linear-gradient(-11deg, rgb(188, 101, 216), rgb(232, 151, 241))"
  };
  const messageStyle = {
    maxWidth: 400,
    color: "white",
    fontWeight: 300,
    width: "fit-content",
    margin: "0 auto",
    padding: "10px 15px"
  };
  return (
    <div className="soon-container" style={containerStyle}>
      <p className="soon-message" style={messageStyle}>
        {data} coming soon!
      </p>
    </div>
  );
}
